<template>
  <div class="modalDialog receive-present-dialog">
    <h1>プレゼント受け取り</h1>
    <template v-if="received_presents && received_presents.length <= 0">
      <p>受け取れるプレゼントがありませんでした</p>
    </template>
    <template v-else>
      <p>以下のアイテムを受け取りました</p>
      <ul class="modalDialog_content">
        <template v-for="(present, i) in received_presents" :key="i">
          <li v-if="present.is_point" class="present_coin">
            コイン x
            <img
              class="icon_point"
              :src="`${config.public.SNS_PATH}/sp/img/layout/blg/icons/icon_coin.png`"
            />{{ present.count }}c
          </li>
          <li v-else-if="present.is_crystal" class="present_crystal">
            クリスタル x
            <img
              class="icon_point"
              :src="`${config.public.SNS_PATH}/sp/img/layout/blg/icons/icon_crystal.png`"
            />{{ present.count }}個
          </li>
          <li v-else-if="present.is_ticket" class="present_ticket">
            {{ present.name }} x
            <img
              class="icon_point"
              :src="`${config.public.SNS_PATH}/sp/img/layout/blg/${present.icon_path}`"
            />{{ present.count }}{{ present.unit }}
          </li>
          <li v-else-if="present.is_card" class="present_card">
            カード「{{ rarity2star(present.rarity) }}{{ present.name }}」 x
            {{ present.count }}
          </li>
          <li v-else-if="present.is_title" class="present_title">
            <img :src="`${config.public.SNS_PATH}${present.profile_icon_url}`"/>
          </li>
          <li v-else-if="present.is_background" class="present_background">
            <img :src="`${config.public.SNS_PATH}${present.background_icon_url}`"/>
          </li>
        </template>
      </ul>
    </template>
    <p class="closeBtn">
      <a href="javascript: void(0)" @click="close">閉じる</a>
    </p>
  </div>
</template>

<script>
/** プレゼント受け取りダイアログコンポーネント
 * @module JpblReceivePresentDialog
 * @vue-props {ReceivePresentDialogCtrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblReceivePresentDialog",
  head() {
    // return {
    //   bodyAttrs: {
    //     class: "modal-body",
    //   },
    // };
  },
});
</script>

<script setup>
import ReceivePresentDialogCtrl from './controller';
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();
const config = useRuntimeConfig();

const props = defineProps({
  ctrl: {
    type: ReceivePresentDialogCtrl,
    required: true,
  },
});

const received_presents = computed(() => props.ctrl.received_presents);
const on_close = computed(() => props.ctrl.on_close);
const close = () => {
console.log('on_close')
console.log(on_close);
	if (on_close.value) {
		on_close.value();
	}
	else {
		dialogAdapter.close();
	}
}
const rarity2star = (rarity) => {
  let rare = "";
  let i;
  for (i = 0; i < rarity; i++) {
    rare += "★";
  }
  return rare;
}
</script>

<style lang="scss" scoped>
.receive-present-dialog {
  @include dialog-width;
}
ul.modalDialog_content {
  padding-left: 0px;
}
ul.modalDialog_content {
  max-height: 40vh;
}
.present_title img{
  width:80%;
  margin:auto;
}
.present_background img{
  width:80%;
  margin:auto;
}
</style>
