import { cf_app_canCallApi, cf_store_payment } from "./client_functions-module";
import {
  cf_app_canCallApi as cf_app_canCallApi_local,
  cf_store_payment as cf_store_payment_local
} from "./local_client_functions-module";
import {
  cf_app_canCallApi as cf_app_canCallApi_wk,
  cf_store_payment as cf_store_payment_wk
} from "./client_functions_wk-module";
import {
  cf_app_canCallApi as cf_app_canCallApi_unsupport_cache,
  cf_store_payment as cf_store_payment_unsupport_cache
} from "./client_functions_unsupport_cache-module";
import {
  cf_app_canCallApi as cf_app_canCallApi_unsupport_cache_wk,
  cf_store_payment as cf_store_payment_unsupport_cache_wk
} from "./client_functions_unsupport_cache_wk-module";

const Context = {
  Default: "default",
  Local: "local",
  WK: "wk",
  UnsupportCache: "unsupport_cache",
  UnsupportCacheWK: "unsupport_cache_wk",
}

export default class ClientFunctionsStrategy {
  /**
   * Contextのプロパティとして列挙されている値
   * @type {string}
   */
  context;

  /**
   * @param {{isIOS: () => boolean}} $ua 
   */
  constructor($ua) {
    if (this._is_browser_debug()) this.context = Context.Local;
    else if (this._is_unsupport_cache()) {
      if (this._is_wk_webview($ua)) this.context = Context.UnsupportCacheWK;
      else this.context = Context.UnsupportCache;
    }
    else if (this._is_wk_webview($ua)) this.context = Context.WK;
    else this.context = Context.Default;
  }

  /**
   * @return {(onReturnCallback: any, isCan: boolean) => void}
   */
  get cf_app_canCallApi() {
    switch (this.context) {
      case Context.WK:
        return cf_app_canCallApi_wk;
      case Context.Local:
        return cf_app_canCallApi_local;
      case Context.UnsupportCache:
        return cf_app_canCallApi_unsupport_cache;
      case Context.UnsupportCacheWK:
        return cf_app_canCallApi_unsupport_cache_wk;
      default:
        return cf_app_canCallApi;
    }
  }

  /**
   * @return {(onReturnCallback: any, productId: string) => void}
   */
  get cf_store_payment() {
    switch (this.context) {
      case Context.WK:
        return cf_store_payment_wk;
      case Context.Local:
        return cf_store_payment_local;
      case Context.UnsupportCache:
        return cf_store_payment_unsupport_cache;
      case Context.UnsupportCacheWK:
        return cf_store_payment_unsupport_cache_wk;
      default:
        return cf_store_payment;
    }
  }

  _is_browser_debug() {
    return false;
  }

  _is_unsupport_cache() {
    return false;
  }

  /**
   * @param {{isIOS: () => boolean}} $ua 
   */
  _is_wk_webview($ua) {
    return $ua.isIOS();
  }
}