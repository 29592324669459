<template>
  <v-card class="enter-credit-card-dialog">
    <v-card-title
      class="text-subtitle-1 font-weight-bold bg-grey-darken-1 text-white d-flex justify-center mb-4"
    >
      クレジットカード入力
    </v-card-title>
    <div class="dialog-body scrollable">
      <v-form
        ref="form"
        class="enter-credit-card-dialog__form pa-3"
        lazy-validation
      >
        <div v-if="is_cust_id_purchasable" class="cust_manage">
          <v-checkbox
            v-model="isCustIdPurchase"
            label="前回利用のカードで支払う"
          />
          <div
            class="last_card_info"
            :class="isCustIdPurchase == 0 ? 'disable-input' : ''"
          >
            <v-text-field
              v-model="last_credit_card_info.cc_number"
              readonly
              variant="outlined"
              required
              autocomplete="off"
              type="tel"
            ></v-text-field>
          </div>
        </div>
        <div
          class="credit_card_form"
          :class="isCustIdPurchase == 1 ? 'disable-input' : ''"
        >
          <div class="enter-credit-card-dialog__form__card-num">
            <label class="enter-credit-card-dialog__form__card-num__label"
              >クレジットカード番号</label
            >
            <v-text-field
              v-model="creditCard.numberFormatted"
              :rules="numberRules"
              :validate-on="true && 'blur'"
              placeholder="半角数字"
              variant="outlined"
              required
              autocomplete="off"
              type="tel"
            ></v-text-field>
          </div>
          <div class="enter-credit-card-dialog__form__expiry-month">
            <label class="enter-credit-card-dialog__form__expiry-month__label"
              >有効期限</label
            >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="creditCard.monthStr"
                  :rules="monthRules"
                  :validate-on="true && 'blur'"
                  placeholder="01"
                  suffix="月"
                  variant="outlined"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="creditCard.year"
                  :rules="yearRules"
                  :validate-on="true && 'blur'"
                  placeholder="2030"
                  suffix="年"
                  variant="outlined"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="enter-credit-card-dialog__form__security-code">
            <label class="enter-credit-card-dialog__form__security-code__label"
              >セキュリティコード</label
            >
            <v-text-field
              v-model="creditCard.securityCode"
              :rules="securityCodeRules"
              :validate-on="true && 'blur'"
              placeholder="123"
              variant="outlined"
              required
            ></v-text-field>
          </div>
          <v-checkbox
            v-model="creditCard.custManage"
            true-value="1"
            false-value="0"
            label="クレジットカード番号を保存する"
          />
        </div>
      </v-form>
      <v-divider style="border-color: black"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn min-width="100px" variant="outlined" @click="back">戻る</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          variant="flat"
          min-width="100px"
          color="primary font-weight-bold"
          @click.once="getToken"
        >
          決定
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-divider style="border-color: black"></v-divider>
    </div>
  </v-card>
</template>

<script>
/** クレカ入力ダイアログコンポーネント
 * @module JpblEnterCreditCardDialog
 * @vue-props {EnterCreditCardDialogCtrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblEnterCreditCardDialog",
});
</script>

<script setup>
import EnterCreditCardDialogCtrl from "./controller";
import CreditCardValidator from "~/utils/validator/payment/credit_card";
import { ValidationCode } from "~/utils/validator";
import { PayByCreditCardCommand } from "~/commands/payment";
import SimpleDialog2Ctrl from "~/components/jpbl/UI/dialog/simple_dialog_2/controller";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();
const { reloadHeader } = useHeaderData();

const props = defineProps({
  ctrl: {
    type: EnterCreditCardDialogCtrl,
    required: true,
  },
});
const config = useRuntimeConfig();
const sbpsScript = document.createElement("script");
sbpsScript.setAttribute("src", config.public.CC_PAYMENT_TOKEN_JS_URL);
document.head.appendChild(sbpsScript);

const back = () => dialogAdapter.close();

const paymentCommon = ref(props.ctrl.paymentCommon);
const creditCard = ref(props.ctrl.creditCard);
const is_cust_id_purchasable = ref(props.ctrl.is_cust_id_purchasable);
const last_credit_card_info = ref(props.ctrl.last_credit_card_info);
const isCustIdPurchase = ref(props.ctrl.is_cust_id_purchasable ? true : false);

const getToken = () => {
  if (isCustIdPurchase.value) {
    execute({
      result: "OK",
    });
  } else {
    if (
      paymentCommon.value.payMethod === "credit" &&
      paymentCommon.value.payType === "token"
    ) {
      com_sbps_system.generateToken(
        {
          merchantId: config.public.CC_PAYMENT_MERCHANT_ID,
          serviceId: config.public.CC_PAYMENT_SERVICE_ID,
          ccNumber: creditCard.value.number,
          ccExpiration: `${creditCard.value.year}${creditCard.value.monthStr}`,
          securityCode: creditCard.value.securityCode,
        },
        execute,
      );
    }
  }
};

const execute = async (response) => {
  if (response.result === "OK") {
    if (!isCustIdPurchase.value) {
      creditCard.value.token = response.tokenResponse.token;
      creditCard.value.tokenKey = response.tokenResponse.tokenKey;
    } else {
      creditCard.value.custManage = 1;
      creditCard.value.isCustIdPurchase = 1;
    }
    const command = new PayByCreditCardCommand(
      paymentCommon.value,
      creditCard.value,
    );
    const result = await command.execute();

    if (result.successful) {
      reloadHeader();

      dialogAdapter.open(
        new SimpleDialog2Ctrl({
          headerText: "完了",
          buttonPrompt: `${paymentCommon.value.itemName}を購入しました`,
        }),
      );
    } else {
      dialogAdapter.open(
        new SimpleDialog2Ctrl({
          headerText: "エラー",
          buttonPrompt: `${paymentCommon.value.itemName}の購入に失敗しました。時間を置いて再度お試しいただくか、管理者にご連絡くださいますようお願い申し上げます。`,
        }),
      );
    }
  } else {
    console.log("トークン取得に失敗しました。");
    dialogAdapter.open(
      new SimpleDialog2Ctrl({
        headerText: "エラー",
        buttonPrompt: `${paymentCommon.value.itemName}の購入に失敗しました。時間を置いて再度お試しいただくか、管理者にご連絡くださいますようお願い申し上げます。`,
      }),
    );
  }
  setTimeout(() => location.reload(), 3000);
};

const numberRules = [
  (v) =>
    CreditCardValidator.validateHyphenatedCardNumber(v).code !==
      ValidationCode.IllegalChars || "半角数字のみで入力してください",
  (v) =>
    CreditCardValidator.validateHyphenatedCardNumber(v).code !==
      ValidationCode.LessThanMin || "15桁以上である必要があります",
  (v) =>
    CreditCardValidator.validateHyphenatedCardNumber(v).code !==
      ValidationCode.MoreThanMax || "19桁以下である必要があります",
  (v) =>
    CreditCardValidator.validateHyphenatedCardNumber(v).code !==
      ValidationCode.IllegalFormat || "カード番号が不正です",
];

const monthRules = [
  (v) =>
    CreditCardValidator.validate2DigitExpiryMonth(v).code !==
      ValidationCode.EmptyNotAllowed || "有効期限月は必須です",
  (v) =>
    CreditCardValidator.validate2DigitExpiryMonth(v).code !==
      ValidationCode.IllegalChars || "半角数字のみで入力してください",
  (v) =>
    CreditCardValidator.validate2DigitExpiryMonth(v).code !==
      ValidationCode.LessThanMin || "無効な月です",
  (v) =>
    CreditCardValidator.validate2DigitExpiryMonth(v).code !==
      ValidationCode.MoreThanMax || "無効な月です",
];

const yearRules = [
  (v) =>
    CreditCardValidator.validateExpiryYear(v).code !==
      ValidationCode.EmptyNotAllowed || "有効期限年は必須です",
  (v) =>
    CreditCardValidator.validateExpiryYear(v).code !==
      ValidationCode.IllegalChars || "半角数字のみで入力してください",
  (v) =>
    CreditCardValidator.validateExpiryYear(v).code !==
      ValidationCode.IllegalLength || "無効な年です",
];

const securityCodeRules = [
  (v) =>
    CreditCardValidator.validateSecurityCode(v).code !==
      ValidationCode.EmptyNotAllowed || "セキュリティコードは必須です",
  (v) =>
    CreditCardValidator.validateSecurityCode(v).code !==
      ValidationCode.IllegalChars || "半角数字のみで入力してください",
  (v) =>
    CreditCardValidator.validateSecurityCode(v).code !==
      ValidationCode.IllegalLength || "無効なセキュリティコードです",
];
</script>

<style lang="scss" scoped>
.enter-credit-card-dialog {
  &__form {
    &__label {
      font-size: 14px;
      display: block;
      margin-bottom: 10px;
    }

    &__card-num {
      &__label {
        @extend .enter-credit-card-dialog__form__label;
      }
    }

    &__expiry-month {
      &__label {
        @extend .enter-credit-card-dialog__form__label;
      }
    }

    &__security-code {
      &__label {
        @extend .enter-credit-card-dialog__form__label;
      }
    }

    &__name {
      &__label {
        @extend .enter-credit-card-dialog__form__label;
      }
    }
  }
  :deep(.v-input__slot) {
    min-height: 40px;
  }
  :deep(.v-input--selection-controls.v-input .v-input__slot) {
    min-height: 20px;
  }
  :deep(.v-input--selection-controls) {
    margin-top: 0px;
    padding-top: 0px;
  }
  .disable-input {
    display: none;
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(0.3) brightness(0.4);
  }
  .disable-input::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    content: " ";
  }
}
.dialog-body {
  min-height: 60vh;
}
</style>
