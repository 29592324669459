<template>
  <img :src="src" />
</template>

<script>
/**
 * @module
 * @vue-props {String} season
 */
export default defineNuxtComponent({
  name: "jpblAlbumImg",
});
</script>

<script setup>
const config = useRuntimeConfig();

const album17 =
  config.public.SNS_PATH +
  "sp/img/layout/blg/btn-select-album_2017.png?t=20200924001";
const album18 =
  config.public.SNS_PATH +
  "sp/img/layout/blg/btn-select-album_2018.png?t=20200924001";
const album19 =
  config.public.SNS_PATH +
  "sp/img/layout/blg/btn-select-album_2019.png?t=20200924001";
const album20 =
  config.public.SNS_PATH +
  "sp/img/layout/blg/btn-select-album_2020.png?t=20200924001";
const album21 =
  config.public.SNS_PATH +
  "sp/img/layout/blg/btn-select-album_2021.png?t=20200924001";
const album22 =
    config.public.SNS_PATH +
    "sp/img/layout/blg/btn-select-album_2022.png?t=20200924001";
const album23 =
    config.public.SNS_PATH +
    "sp/img/layout/blg/btn-select-album_2023.png?t=2023";
const imgMap = {
  2017: album17,
  2018: album18,
  2019: album19,
  2020: album20,
  2021: album21,
  2022: album22,
  2023: album23,
};

const props = defineProps({
  season: {
    type: String,
    required: true,
  },
});

const src = imgMap[props.season];
</script>

<style lang="scss" scoped></style>
