import { ValidationCode, ValidationResult } from ".";

/**
 * 電話番号をバリデーションする
 * @param {string} tel
 * @returns {ValidationResult}
 */
export function validateTel(tel) {
  /** 空でないこと */
  if (!tel)
    return ValidationResult.createInvalid(
      "電話番号は空でない必要があります",
      ValidationCode.EmptyNotAllowed
    );

  /** 半角数字のみであること */
  if (/[^0-9]/.test(tel))
    return ValidationResult.createInvalid(
      "電話番号は半角数字のみである必要があります",
      ValidationCode.IllegalChars
    );

  /** 10桁以上であること */
  if (tel.length < 10)
    return ValidationResult.createInvalid(
      "電話番号は10桁以上である必要があります",
      ValidationCode.LessThanMin
    );

  /** 16桁以下であること */
  if (tel.length > 16)
    return ValidationResult.createInvalid(
      "電話番号は16桁以下である必要があります",
      ValidationCode.MoreThanMax
    );

  return ValidationResult.createValid();
}
