import { validateGeneralName } from "~/utils/validator/general_name";

/**
 * リンク型決済手段の共通クラス
 */
export default class LinkPayment {
}

export class LinkPaymentBuilder {
  constructor($config) {
    this.$config = $config;
  }

  build() {
    const lp = new LinkPayment();

    // local/dev環境の場合にはデフォルト値をセット
    if (["local", "development"].includes(process.env.NODE_ENV)) {
    }

    return lp;
  }
}
