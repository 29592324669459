<template>
  <div class="simple-dialog-2">
    <h1 class="simple-dialog-2__header">{{ props.ctrl.headerText }}</h1>
    <div class="simple-dialog-2__body">
      <h2
        v-if="props.ctrl.bodyTitle"
        class="simple-dialog-2__body__title"
      >{{ props.ctrl.bodyTitle }}</h2>
      <p
        v-if="texts.length > 0"
        class="simple-dialog-2__body__text"
      >
        <template v-if="isHtml">
          <div v-html="$sanitize(textsHtml)"></div>
        </template>
        <template v-else>
          <span
            v-for="(text, idx) in texts"
            :key="idx"
            class="simple-dialog-2__body__text__line"
            >{{ text }}</span
          >
        </template>
      </p>
      <p
        v-if="props.ctrl.buttonPromptHtml"
        class="simple-dialog-2__body__text"
        v-html="props.ctrl.buttonPromptHtml"
      ></p>
      <div class="simple-dialog-2__body__btn-group">
        <button
          v-for="button in buttons"
          :key="button.key"
          class="simple-dialog-2__body__btn-group__btn"
          @click="button.onClick"
        >
          {{ button.key }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/** simpledialog2コンポーネント
 * @module JpblSimpleDialog2
 * @vue-props {SimpleDialog2Ctrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblSimpleDialog2",
});
</script>

<script setup>
import SimpleDialog2Ctrl from "./controller";

const props = defineProps({
  ctrl: {
    type: SimpleDialog2Ctrl,
    required: true,
  },
});

const texts = computed(() => {
  const prompt = props.ctrl.buttonPrompt;
  if (!prompt) return [];
  return Array.isArray(prompt) ? prompt : [prompt];
});
const textsHtml = computed(() => texts.value?.join("<br>"));

const buttons = computed(() => {
  if (!props.ctrl.buttons) return [];

  return Object.entries(props.ctrl.buttons).map(([key, { click }]) => ({
    key,
    onClick: useGuardReentancy(click),
  }));
});

const isHtml = computed(()=>props.ctrl.isHtml)

onMounted(() => {
  if (props.ctrl.onMounted) props.ctrl.onMounted();
  setTimeout(() => {
    Array.from(document.querySelectorAll("page-link"), (tag) => {
      tag.addEventListener("click", (event) => {
        let link = tag.getAttribute("href");
        let onclick = tag.getAttribute("onClick");
        if (onclick.indexOf('openExternal')) {
          let sp = onclick.split("'");
          window.open(sp[1], '_blank', 'noopener');
          return;
        }
        let tmp = link.split("/");
        tmp.shift();
        tmp[0] = ""; //jpblを削除する
        let path = tmp.join("/");
        $router.push({ path: path });
      });
    });
  }, 50);
});

</script>

<style lang="scss" scoped>
.simple-dialog-2 {
  &__header {
    background-color: #828282;
    color: #fff;
    min-height: 1.1em;
    text-align: center;
    display: block;
    padding: 10px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
  }

  &__body {
    background-color: #d8d8d8;
    border: none;
    font-size: 14px;
    overflow: hidden;
    padding: 0 10px;

    &__title {
      margin-top: 15px;
      font-size: 18px;
      font-weight: bold;
      text-align: center
    }

    &__text {
      color: #151515;
      text-align: center;
      padding-top: 15px;
      font-size: 12px;

      &__line {
        display: block
      }
    }

    &__btn-group {
      margin-top: 5px;
      padding: 10px 24px 20px;

      &__btn {
        background-color: white;
        width: 100%;
        font-weight: bold;
        padding: 10px 0;
        border-radius: 4px;
        margin-bottom: 10px
      }
    }
  }
}
</style>
