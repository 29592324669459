import { PaymentCommon } from "~/models/payment";
import CreditCard from "~/models/payment/credit_card";
import { DialogType } from "../../jpbl/UI/dialog";
import BaseDialogCtrl from "../../jpbl/UI/dialog/base/controller";
export default class EnterCreditCardDialogCtrl extends BaseDialogCtrl {
  _type = DialogType.EnterCreditCard;

  /**
   * @type {PaymentCommon}
   */
  paymentCommon

  /**
   * @type {CreditCard}
   */
  creditCard

  /**
   * クレカ前回情報支払い
   * @type Boolean
   */
  is_cust_id_purchasable;
  /**
   * クレカ前回情報
   * @type Object
   */
  last_credit_card_info;

  /**
   * @param {PaymentCommon} paymentCommon 
   * @param {CreditCard} creditCard 
   */
  constructor(paymentCommon, creditCard, is_cust_id_purchasable, last_credit_card_info) {
    super();

    this.paymentCommon = paymentCommon;
    this.creditCard = creditCard;
    this.is_cust_id_purchasable = is_cust_id_purchasable;
    this.last_credit_card_info = last_credit_card_info;

  }
}
