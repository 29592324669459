<template>
  <div class="jpbl-login">
  </div>
</template>

<script>
export default defineNuxtComponent({
  name: "jpblLogin",
});
</script>

<script setup>

</script>

<style lang="scss" scoped>
</style>
