<template>
  <div class="menu">
    <a style="pointer-events: all" @click="click"
      ><img
        class="btn_menu"
        :src="`${config.public.SNS_PATH}sp/img/2019/btn_menu_60.png`"
    /></a>
  </div>
</template>

<script>
/** メニューボタンコンポーネント
 * @module MenuBtn
 */
export default defineNuxtComponent({
  name: "jpblMenuBtn",
});
</script>

<script setup>
import MenuPanelDialogCtrl from "../UI/dialog/menu_panel/controller";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();
const config = useRuntimeConfig();

const click = () => dialogAdapter.open(new MenuPanelDialogCtrl());
</script>

<style lang="scss" scoped>
.btn_menu {
  width: 49px;
  height: 44px;
  z-index: 20;
}
</style>
