import { validateEmail } from "~/utils/validator/email";
import { validateGeneralName } from "~/utils/validator/general_name";
import { validateNameKana } from "~/utils/validator/name_kana";
import { validateNameKanji } from "~/utils/validator/name_kanji";
import { validatePostalCode } from "~/utils/validator/postal_code";
import { validateTel } from "~/utils/validator/tel";

export default class NPPayment {
  /**
   * 会社名
   * @type {string}
   */
  _companyName = "";

  /**
  * @returns {string}
  */
  get companyName() {
    return this._companyName;
  }

  /**
  * @param {string}
  */
  set companyName(val) {
    return this._companyName = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateCompanyName() {
    const result = validateGeneralName(this.companyName);
    if (!result.isValid) throw result.toError();
  }
  
  /**
   * 部署名
   * @type {string}
   */
  _departmentName = "";

  /**
 * @returns {string}
 */
  get departmentName() {
    return this._departmentName;
  }

  /**
 * @param {string}
 */
  set departmentName(val) {
    return this._departmentName = val.trim();
  }
 
  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDepartmentName() {
    const result = validateGeneralName(this.departmentName);
    if (!result.isValid) throw result.toError();
  }

  /**
   * 漢字氏名
   * @type {string}
   */
  _nameKanji = "";

  /**
   * @returns {string}
   */
  get nameKanji() {
    return this._nameKanji;
  }

  /**
   * @param {string}
   */
  set nameKanji(val) {
    return this._nameKanji = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateNameKanji() {
    const result = validateNameKanji(this.nameKanji);
    if (!result.isValid) throw result.toError();
  }
  
  /**
   * カナ氏名
   * @type {string}
   */
  _nameKana = "";

  /**
   * @returns {string}
   */
  get nameKana() {
    return this._nameKana;
  }

  /**
   * @param {string}
   */
  set nameKana(val) {
    return this._nameKana = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateNameKana() {
    const result = validateNameKana(this.nameKana);
    if (!result.isValid) throw result.toError();
  }
  
  /**
   * 郵便番号
   * @type {string}
   */
  _postalCode = "";

  /**
    * @returns {string}
    */
  get postalCode() {
    return this._postalCode;
  }

  /**
    * @param {string}
    */
  set postalCode(val) {
    return this._postalCode = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validatePostalCode() {
    const result = validatePostalCode(this.postalCode);
    if (!result.isValid) throw result.toError();
  }
  
  /**
   * 住所
   * @type {string}
   */
  _address = "";

  /**
   * @returns {string}
   */
  get address() {
    return this._address;
  }

  /**
   * @param {string}
   */
  set address(val) {
    return this._address = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateAddress() {
    const result = validateGeneralName(this.address);
    if (!result.isValid) throw result.toError();
  }

  
  /**
   * 電話番号
   * @type {string}
   */
  _tel = "";

  /**
   * @returns {string}
   */
  get tel() {
    return this._tel;
  }

  /**
   * @param {string}
   */
  set tel(val) {
    return this._tel = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateTel() {
    const result = validateTel(this.tel);
    if (!result.isValid) throw result.toError();
  }
  
  /**
   * メールアドレス
   * @type {string}
   */
  _email = "";

  /**
   * @returns {string}
   */
  get email() {
    return this._email;
  }

  /**
   * @param {string}
   */
  set email(val) {
    return this._email = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateEmail() {
    const result = validateEmail(this.email);
    if (!result.isValid) throw result.toError();
  }
  
  /**
   * 配送先会社名
   * @type {string}
   */
  _destCompanyName = "";

  /**
  * @returns {string}
  */
  get destCompanyName() {
    return this._destCompanyName;
  }

  /**
  * @param {string}
  */
  set destCompanyName(val) {
    return this._destCompanyName = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDestCompanyName() {
    const result = validateGeneralName(this.destCompanyName);
    if (!result.isValid) throw result.toError();
  }

  /**
   * 配送先部署名
   * @type {string}
   */
  _destDepartmentName = "";

  /**
 * @returns {string}
 */
  get destDepartmentName() {
    return this._destDepartmentName;
  }

  /**
 * @param {string}
 */
  set destDepartmentName(val) {
    return this._destDepartmentName = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDestDepartmentName() {
    const result = validateGeneralName(this.destDepartmentName);
    if (!result.isValid) throw result.toError();
  }
  
  /**
   * 配送先漢字氏名
   * @type {string}
   */
  _destNameKanji = "";

  /**
  * @returns {string}
  */
  get destNameKanji() {
    return this._destNameKanji;
  }

  /**
  * @param {string}
  */
  set destNameKanji(val) {
    return this._destNameKanji = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDestNameKanji() {
    const result = validateNameKanji(this.destNameKanji);
    if (!result.isValid) throw result.toError();
  }
  
  /**
  * 配送先カナ氏名
  * @type {string}
  */
  _destNameKana = "";

  /**
  * @returns {string}
  */
  get destNameKana() {
    return this._destNameKana;
  }

  /**
  * @param {string}
  */
  set destNameKana(val) {
    return this._destNameKana = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDestNameKana() {
    const result = validateNameKana(this.destNameKana);
    if (!result.isValid) throw result.toError();
  }

  /**
   * 配送先郵便番号
   * @type {string}
   */
  _destPostalCode = "";

  /**
   * @returns {string}
   */
  get destPostalCode() {
    return this._destPostalCode;
  }

  /**
   * @param {string}
   */
  set destPostalCode(val) {
    return this._destPostalCode = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDestPostalCode() {
    const result = validatePostalCode(this.destPostalCode);
    if (!result.isValid) throw result.toError();
  }
  
  /**
  * 配送先住所
  * @type {string}
  */
  _destAddress = "";

  /**
  * @returns {string}
  */
  get destAddress() {
    return this._destAddress;
  }

  /**
  * @param {string}
  */
  set destAddress(val) {
    return this._destAddress = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDestAddress() {
    const result = validateGeneralName(this.destAddress);
    if (!result.isValid) throw result.toError();
  }
  
  /**
  * 配送先電話番号
  * @type {string}
  */
  _destTel = "";

  /**
  * @returns {string}
  */
  get destTel() {
    return this._destTel;
  }

  /**
  * @param {string}
  */
  set destTel(val) {
    return this._destTel = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateDestTel() {
    const result = validateTel(this.destTel);
    if (!result.isValid) throw result.toError();
  }
  
  /**
  * 運送会社コード
  * @type {string}
  */
  _transporterCode = "";

  /**
 * @returns {string}
 */
  get transporterCode() {
    return this._transporterCode;
  }

  /**
 * @param {string}
 */
  set transporterCode(val) {
    return this._transporterCode = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateTransporterCode() {
    const result = validateGeneralName(this.transporterCode);
    if (!result.isValid) throw result.toError();
  }
  
  /**
  * 配送伝票番号
  * @type {string}
  */
  _slipNo = "";

  /**
* @returns {string}
*/
  get slipNo() {
    return this._slipNo;
  }

  /**
* @param {string}
*/
  set slipNo(val) {
    return this._slipNo = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateSlipNo() {
    const result = validateGeneralName(this.slipNo);
    if (!result.isValid) throw result.toError();
  }
}

export class NPPaymentBuilder {
  constructor($config) {
    this.$config = $config;
  }

  build() {
    const np = new NPPayment();

    // local/dev環境の場合にはデフォルト値をセット
    if (["local", "development"].includes(process.env.NODE_ENV)) {
    }

    return np;
  }
}