<template>
  <!-- トップページはバックボタンなし || コントローラからバックボタン非表示が指定されてる -->
  <a
    v-if="data.hide_back_btn != null"
    data-ajax="false"
    href="javascript:void(0);"
    style="pointer-events: all"
    :onclick="
      back_location
        ? `location.href='${back_location}';`
        : 'javascript:window.history.back(-1);return false;'
    "
  >
    <img
      class="btn_back"
      :src="`${config.public.SNS_PATH}sp/img/2019/btn_back_60.png`"
    />
  </a>
</template>

<script>
/** バックボタンコンポーネント
 * @module BackBtn
 */
export default defineNuxtComponent({
  name: "jpblBackBtn",
});
</script>

<script setup>
const config = useRuntimeConfig();
// 以下、移植作業用のダミー値
const data = {
  hide_back_btn: false,
};
const back_location = null;
// 以上、移植作業用のダミー値
</script>

<style lang="scss" scoped>
#link_close_head {
  width: 18px;
  display: block;
}

.btn_back {
  width: 49px;
  height: 44px;
  z-index: 200;
}
</style>
