<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      Dialog Title
    </v-card-title>

    <v-card-text>
      Dialog Text
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="props.ctrl.close"
      >
        Button
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/** デフォルトダイアログコンポーネント
 * @module JpblDefaultDialog
 * @vue-props {DefaultDialogCtrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblDefaultDialog",
});
</script>

<script setup>
import DefaultDialogCtrl from './controller';

const props = defineProps({
  ctrl: {
    type: DefaultDialogCtrl,
    required: true,
  },
});
</script>

<style scoped>
</style>
