<template>
  <div>
    <div id="monthlyRankingBG" class="monthly_ranking_popup_bg"></div>
    <div id="monthlyRankingBox" class="monthly_ranking_popup_box">
      <img
        :src="
          imgUrlBuilder('/sp/img/2020/monthly_ranking/top_monthly_effect1.png')
        "
        class="effect1"
      />
      <img
        :src="
          imgUrlBuilder('/sp/img/2020/monthly_ranking/top_monthly_effect2.png')
        "
        class="effect2"
      />
      <div class="btn_ranking" @click="goToRanking">
        <img :src="`${config.public.SNS_PATH}sp/img/2020/btn_ranking.png`" />
      </div>
      <img
        :src="
          imgUrlBuilder(
            '/sp/img/2020/monthly_ranking/top_monthly_effect_arrow.png'
          )
        "
        class="effect_arrow"
      />
      <img
        :src="
          imgUrlBuilder(
            '/sp/img/2020/monthly_ranking/top_monthly_effect_text.png'
          )
        "
        class="effect_text"
      />
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  name: "jpblMonthlyRankingDialog",
});
</script>
<script setup>
import moment from "moment";
import { VIEWPORT_WIDTH_PX } from "~/constants";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

const config = useRuntimeConfig();
const { $router } = useNuxtApp();
const imgUrlBuilder = (path) => {
  return config.public.SNS_PATH + path;
}

const goToRanking = () => {
  dialogAdapter.close();

  $router.push({
    path: "/rarerank/summary/",
    query: { now: moment().unix(), summary: "monthly" },
  });
};

onMounted(() => {
  $(".ui-page").css({ position: "fixed" });
  $("#monthlyRankingBG").fadeIn();
  $("#monthlyRankingBox").fadeIn();

  // PC表示用の対応
  const left =
    window.innerWidth - VIEWPORT_WIDTH_PX > 0
      ? (window.innerWidth - VIEWPORT_WIDTH_PX) / 2
      : 0;

  setTimeout(function () {
    $("#monthlyRankingBox .effect1")
      .css("left", `${left}px`)
      .addClass("anim");
    setTimeout(function () {
      $("#monthlyRankingBox .effect2")
        .css("left", `${left + 30}px`)
        .addClass("anim");
      setTimeout(function () {
        $("#monthlyRankingBox .effect_arrow")
          .css("left", `${left + 36}px`)
          .addClass("anim");
        $("#monthlyRankingBox .effect_text")
          .css("left", `${left + 36}px`)
          .addClass("anim");
        setTimeout(function () {
          $("#monthlyRankingBox .btn_ranking").css({
            left: `${left + 17}px`,
            visibility: "visible",
          });
        }, 250);
      }, 500);
    }, 500);
  }, 500);
});
</script>

<style lang="scss" scoped>
$header-height: 46px;

.monthly_ranking_popup_bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 80%);
  z-index: 1005;
}

.monthly_ranking_popup_box {
  display: none;
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: translateY(-50%);
  z-index: 1006;
}

.monthly_ranking_popup_box .effect1 {
  position: absolute;
  top: calc(20px + #{$header-height});
  display: block;
  width: 375px;
  transform: scale(1.5);
  opacity: 0;
}

.monthly_ranking_popup_box .effect2 {
  position: absolute;
  top: calc(200px + #{$header-height});
  display: block;
  width: 315px;
  transform: scale(1.5);
  opacity: 0;
}

.monthly_ranking_popup_box .btn_ranking {
  position: absolute;
  top: calc(341px + #{$header-height});
  display: block;
  width: 77px;
  visibility: hidden;

  img {
    width: 100%;
  }
}

.monthly_ranking_popup_box .effect_arrow {
  position: absolute;
  top: calc(445px + #{$header-height});
  display: block;
  width: 38px;
  transform: scale(1.5);
  opacity: 0;
}

.monthly_ranking_popup_box .effect_text {
  position: absolute;
  top: calc(485px + #{$header-height});
  display: block;
  width: 260px;
  transform: scale(1.5);
  opacity: 0;
}

.anim {
  animation: slide-in 0.2s ease-in forwards;
}

@keyframes slide-in {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
