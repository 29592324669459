<template>
  <v-dialog
    v-model="showDialog"
    :transition="ctrl ? ctrl.transition : null"
    :width="dialogWidth"
  >
    <JpblDefaultDialog v-if="isDefault" :ctrl="ctrl" />
    <JpblMenuPanelDialog v-else-if="isMenuPanel" :ctrl="ctrl" />
    <JpblChooseAlbumDialog v-else-if="isChooseAlbum" :ctrl="ctrl" />
    <JpblCrystalPurchaseDialog v-else-if="isCrystalPurchase" :ctrl="ctrl" />
    <JpblSelectPaymentMethodDialog
      v-else-if="isSelectPaymentMethod"
      :ctrl="ctrl"
    />
    <JpblEnterCreditCardDialog v-else-if="isEnterCreditCard" :ctrl="ctrl" />
    <JpblEnterNPPaymentDialog v-else-if="isEnterNPPayment" :ctrl="ctrl" />
    <JpblInformationDialog v-else-if="isInformation" :ctrl="ctrl" />
    <JpblSimpleDialog2 v-else-if="isSimpleDialog2" :ctrl="ctrl" />
    <JpblMonthlyRanking v-else-if="isMonthlyRanking" :ctrl="ctrl" />
    <JpblNewLoginBonus v-else-if="isNewLoginBonus" :ctrl="ctrl" />
    <JpblAccessPoint v-else-if="isAccessPoint" :ctrl="ctrl" />
    <JpblReceivePresentDialog v-else-if="isReceivePresent" :ctrl="ctrl" />
  </v-dialog>
</template>

<script>
/** ベースダイアログコンポーネント
 * @module JpblBaseDialog
 * @vue-props {DialogStack} dialogStack
 */
export default defineNuxtComponent({
  name: "jpblBaseDialog",
});
</script>

<script setup>
import { DialogType } from "..";
import JpblDefaultDialog from "../default/index";
import JpblMenuPanelDialog from "../menu_panel/index";
import JpblChooseAlbumDialog from "../folder/choose_album/index";
import JpblCrystalPurchaseDialog from "../crystal_purchase/index";
import JpblSelectPaymentMethodDialog from "../../../../payment/select_payment_method/index";
import JpblEnterCreditCardDialog from "../../../../payment/enter_credit_card/index";
import JpblEnterNPPaymentDialog from "../../../../payment/enter_np_payment/index";
import JpblInformationDialog from "../information/index";
import JpblSimpleDialog2 from "../simple_dialog_2/index";
import JpblMonthlyRanking from "../monthly_ranking/index";
import JpblNewLoginBonus from "../new_login_bonus/index";
import JpblAccessPoint from "../access_point/index";
import JpblReceivePresentDialog from "../receive_present/index";
import { VIEWPORT_WIDTH_PX } from "~/constants";
import { useDialogAdapter } from "~/composables/useDialogAdapter";

const V_DIALOG_MARGIN = 24; // v-dialogで設定されるマージン（px）
const dialogWidth = `${VIEWPORT_WIDTH_PX - V_DIALOG_MARGIN * 2}px`;
const { showDialog, ctrl } = useDialogAdapter();

const isDefault = computed(
  () => ctrl.value && ctrl.value.type === DialogType.Default
);
const isMenuPanel = computed(
  () => ctrl.value && ctrl.value.type === DialogType.MenuPanel
);
const isChooseAlbum = computed(
  () => ctrl.value && ctrl.value.type === DialogType.ChooseAlbum
);
const isCrystalPurchase = computed(
  () => ctrl.value && ctrl.value.type === DialogType.CrystalPurchase
);
const isSelectPaymentMethod = computed(
  () => ctrl.value && ctrl.value.type === DialogType.SelectPaymentMethod
);
const isEnterCreditCard = computed(
  () => ctrl.value && ctrl.value.type === DialogType.EnterCreditCard
);
const isEnterNPPayment = computed(
  () => ctrl.value && ctrl.value.type === DialogType.EnterNPPayment
);
const isInformation = computed(
  () => ctrl.value && ctrl.value.type === DialogType.Information
);
const isSimpleDialog2 = computed(
  () => ctrl.value && ctrl.value.type === DialogType.SimpleDialog2
);
const isMonthlyRanking = computed(
  () => ctrl.value && ctrl.value.type === DialogType.MonthlyRanking
);
const isNewLoginBonus = computed(
  () => ctrl.value && ctrl.value.type === DialogType.NewLoginBonus
);
const isAccessPoint = computed(
  () => ctrl.value && ctrl.value.type === DialogType.AccessPoint
);
const isReceivePresent = computed(
  () => ctrl.value && ctrl.value.type === DialogType.ReceivePresent
);
</script>

<style>
.v-overlay__content {
  touch-action: none;
}
</style>
