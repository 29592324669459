<template>
  <v-card class="select-payment-method-dialog">
    <v-card-title
      class="text-subtitle-1 font-weight-bold bg-grey-darken-1 text-white d-flex justify-center mb-4"
    >
      決済手段選択
    </v-card-title>

    <v-list-item
      v-for="paymentMethod in paymentMethods"
      :key="paymentMethod"
      :ripple="false"
      @click="select(paymentMethod)"
    >
      <template #prepend>
        <v-icon v-if="selectedMethod === paymentMethod">
          mdi-radiobox-marked
        </v-icon>
        <v-icon v-else>mdi-radiobox-blank</v-icon>
      </template>
      <v-list-item-subtitle v-if="paymentMethod === PaymentMethod.Credit"
        >クレジットカード</v-list-item-subtitle
      >
      <v-list-item-subtitle v-else-if="paymentMethod === PaymentMethod.NP"
        >NP後払い</v-list-item-subtitle
      >
      <v-list-item-subtitle v-else-if="paymentMethod === PaymentMethod.Docomo"
        >ドコモ払い</v-list-item-subtitle
      >
      <v-list-item-subtitle v-else-if="paymentMethod === PaymentMethod.SoftBank"
        >S!まとめて支払い</v-list-item-subtitle
      >
      <v-list-item-subtitle v-else-if="paymentMethod === PaymentMethod.AUOne"
        >auかんたん決済</v-list-item-subtitle
      >
      <v-list-item-subtitle v-else-if="paymentMethod === PaymentMethod.Webmoney"
        >WebMoney</v-list-item-subtitle
      >
      <v-list-item-subtitle v-else-if="paymentMethod === PaymentMethod.PayPay"
        >PayPay</v-list-item-subtitle
      >
      <v-list-item-subtitle v-else-if="paymentMethod === PaymentMethod.WebCvs"
        >コンビニ</v-list-item-subtitle
      >
    </v-list-item>

    <v-divider style="border-color: black"></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn min-width="100px" variant="outlined" @click="back">戻る</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        variant="flat"
        min-width="100px"
        color="primary font-weight-bold"
        @click="confirm"
      >
        決定
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-divider style="border-color: black"></v-divider>
  </v-card>
</template>

<script>
/** 決済手段選択ダイアログコンポーネント
 * @module JpblSelectPaymentMethodDialog
 * @vue-props {SelectPaymentMethodDialogCtrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblSelectPaymentMethodDialog",
});
</script>

<script setup>
import SelectPaymentMethodDialogCtrl from "./controller";
import { PaymentCommon, PaymentMethod, PaymentType } from "@/models/payment";
import EnterCreditCardDialogCtrl from "@/components/payment/enter_credit_card/controller";
import EnterNPPaymentDialogCtrl from "@/components/payment/enter_np_payment/controller";
import { CreditCardBuilder } from "@/models/payment/credit_card";
import { NPPaymentBuilder } from "~/models/payment/np_payment";
import { GetPaymentLinkCommand } from "~/commands/payment";
import LinkPayment, { LinkPaymentBuilder } from "~/models/payment/link";
import SimpleDialog2Ctrl from "~/components/jpbl/UI/dialog/simple_dialog_2/controller";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

const config = useRuntimeConfig();
const props = defineProps({
  ctrl: {
    type: SelectPaymentMethodDialogCtrl,
    required: true,
  },
});

const item = computed(() => props.ctrl.item);
const is_cust_id_purchasable = computed(
  () => props.ctrl.is_cust_id_purchasable,
);
const last_credit_card_info = computed(() => props.ctrl.last_credit_card_info);

const paymentMethods = Object.getOwnPropertyNames(PaymentMethod).map(
  (key) => PaymentMethod[key],
);

const selectedMethod = ref(PaymentMethod.Credit);

/**
 * @param {string} type - PaymentMethodに列挙されている値
 */
const select = (type) => {
  selectedMethod.value = type;
};

/**
 * このダイアログ（決済手段選択ダイアログ）が閉じれられるまで、決済に関する入力情報がメモリ上に保持される
 */
const paymentCommon = new PaymentCommon({
  amount: item.value.priceAfterTax,
  tax: item.value.tax,
  itemId: item.value.id,
  itemName: item.value.name,
});
const creditCard = new CreditCardBuilder(config).build();
const npPayment = new NPPaymentBuilder(config).build();
const linkPayment = new LinkPaymentBuilder(config).build();

const confirm = async () => {
  if (selectedMethod.value === PaymentMethod.Credit) {
    paymentCommon.payMethod = PaymentMethod.Credit;
    paymentCommon.payType = PaymentType.Token;
    dialogAdapter.open(
      new EnterCreditCardDialogCtrl(
        paymentCommon,
        creditCard,
        is_cust_id_purchasable.value,
        last_credit_card_info.value,
      ),
    );
  } else if (selectedMethod.value === PaymentMethod.NP) {
    paymentCommon.payMethod = PaymentMethod.NP;
    paymentCommon.payType = PaymentType.Normal;
    dialogAdapter.open(new EnterNPPaymentDialogCtrl(paymentCommon, npPayment));
  } else {
    paymentCommon.payMethod = selectedMethod.value;

    const { successful, serverUrl, params } = await new GetPaymentLinkCommand(
      paymentCommon,
      linkPayment,
    ).execute();

    if (successful) {
      /**
       * 各決済サービス画面に遷移する
       */
      const f = document.createElement("form");
      f.style.display = "none";
      document.body.appendChild(f);
      f.action = serverUrl;
      //f.target = "receiver";
      f.method = "POST";
      f.acceptCharset = "shift_jis";

      for (const idx in params) {
        const input = document.createElement("input");
        input.setAttribute("name", params[idx].name);
        input.setAttribute("value", params[idx].value);
        f.append(input);
      }

      f.submit();
      f.remove();
    } else {
      dialogAdapter.open(
        new SimpleDialog2Ctrl({
          headerText: "エラー",
          buttonPrompt:
            "時間を置いて再度お試しいただくか、別の決済手段をお試しいただきますようお願い申し上げます。",
          buttons: {
            OK: {
              click: () => dialogAdapter.closeAll(),
            },
          },
        }),
      );
    }
  }
};

const back = () => dialogAdapter.close();
</script>

<style lang="scss" scoped>
.select-payment-method-dialog {
  &__for-minors {
    font-size: 11px;
  }

  &__img-container {
    width: 100%;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 0;

    &__img {
      margin: -15px 0;
    }

    &__texts {
      color: white;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
