<template>
  <div class="dialog-accesspoint dialog-lg">
    <div>
      <div style="margin: 10px">
        アクセスコインを<br />受け取りました
        <p class="mes_pt plus">
          <span class="xxx">✕</span><span class="num">5</span><br /><span
            class="txt"
            >コイン</span
          >
        </p>
      </div>

      <p v-if="next_mission" class="message">
        <span>連続ログイン{{ next_mission }}日目！</span
        ><br />明日ログインすると<br />クエスト達成!!
      </p>
      <p v-else class="message" style="font-size: x-small">
        ※B.コインは以下の条件で付与されます<br />4:00～15:59 / 16:00～3:59
      </p>
      <div class="close-dialog-ap">
        <a
          rel="close"
          data-role="button"
          role="button"
          class="ui-link ui-btn ui-shadow ui-corner-all"
          @click="close"
          >閉じる</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  name: "jpblAccessPoint",
});
</script>

<script setup>
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();
const config = useRuntimeConfig();

const props = defineProps({
  ctrl: {
    type: Object,
    required: true,
  },
});

const { next_mission } = props.ctrl;

const close = () => dialogAdapter.close();

onMounted(() =>
  $(".dialog-accesspoint")
    .parent(".pop.in")
    .css("background-color", "transparent")
);
</script>
<style lang="scss" scoped>
.dialog-accesspoint {
  background-image: url($snsPath + "sp/img/layout/blg/bg-accesspoint.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 260px;
  width: 280px;
  position: relative;
  padding-top: 10px;
}

.dialog-accesspoint.dialog-lg {
  background-image: url($snsPath + "sp/img/layout/blg/bg-accesspoint-long.png");
  min-height: 312px;
}

.dialog-accesspoint > div {
  color: #fff;
  height: 100%;
  min-height: 260px;
  padding-bottom: 80px;
  text-align: center;
}

.dialog-accesspoint > div .mes_pt.plus {
  text-align: right;
}

.dialog-accesspoint p.message {
  color: #e1d31c;
  font-size: 16px;
}

.dialog-accesspoint p.message > span {
  font-size: 14px;
}

.close-dialog-ap {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.close-dialog-ap > a {
  border-radius: 20px !important;
  margin: 0 auto 30px;
  width: 50%;
  background-color: #fff;
  border: 0;
  font-size: 14px;
  color: black;
}

.dialog-accesspoint span.mes_pt::before,
#activities span.mes_pt::before {
  background-image: url($snsPath + "sp/img/layout/blg/icons/icon_coin.png");
}

.dialog-accesspoint .mes_pt {
  position: relative;
  display: block;
  width: 160px;
  font-size: 25px;

  /* line-height: inherit; */
  font-weight: bold;
  margin: 30px auto;
  padding-left: 64px;
  text-align: left;
  box-sizing: border-box;
}

.dialog-accesspoint .mes_pt::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
  left: -10px;
  width: 64px;
  height: 64px;
  background-image: url($snsPath + "sp/img/layout/blg/icons/icon_coin.png");
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}

/* activity履歴 */
#activities .mes_pt {
}

#activities .mes_pt::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 16px;
  height: 16px;
  background-image: url($snsPath + "sp/img/layout/blg/icons/icon_coin.png");
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}

.dialog-accesspoint > div .mes_pt.plus .xxx {
  transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  left: 44%;
  position: absolute;
  top: 50%;
}

.dialog-accesspoint > div .mes_pt.plus .num {
  display: inline-block;
  font-family: BLGHudson;
  font-size: 70px;
  line-height: 57px;
  margin-top: 5px;
  right: 0;
}

.dialog-accesspoint > div .mes_pt.plus .txt {
  bottom: -24px;
  font-size: 15px;
  font-weight: normal;
  margin-right: 5px;
  position: absolute;
  right: 0;
}
</style>
