/**
 * Informationページ関連のコマンドを集約するファイル
 */
import ConfigurationBuilder from "./api/configuration_builder";
import { InformationApi } from "~/plugins/openapi/api";
export class GetInformationCommand {
  /**
   * @param {InformationApi} $informationApi - Information関連のAPI通信を担うオブジェクト
   */
  constructor(
    id = null,
    $informationApi = new InformationApi(
      new ConfigurationBuilder().buildDefault()
    )
  ) {
    this.$id = id;
    this.$informationApi = $informationApi;
  }

  /**
   * @returns {object} ページに関するJSONデータ
   * @throws {AxiosError}
   */
  execute() {
    // console.log(this.id);
    if (this.$id === null) {
      return this.$informationApi.informationJsonPost().then(({ data }) => data);
    } else {
      return this.$informationApi
        .informationReadedPost(this.$id)
        .then(({ data }) => data);
    }
  }
}

export class MarkInformationAsReadCommand {
  /**
   * @param {any} id
   * @param {InformationApi} $informationApi
   */
  constructor(
    id,
    $informationApi = new InformationApi(
      new ConfigurationBuilder().buildDefault()
    )
  ) {
    this.id = id;
    this.$informationApi = $informationApi;
  }

  /**
   * @returns {Promise<void>}
   * @throws {AxiosError}
   */
  execute() {
    return this.$informationApi.informationReadedPost(this.id);
  }
}
