import { ValidationCode, ValidationResult } from ".";

/**
 * 郵便番号をバリデーションする
 * @param {string} postalCode
 * @returns {ValidationResult}
 */
export function validatePostalCode(postalCode) {
  /** 空でないこと */
  if (!postalCode)
    return ValidationResult.createInvalid(
      "空ない必要があります",
      ValidationCode.EmptyNotAllowed
    );

  /** 半角数字7桁であること */
  if (!/^[0-9]{7}$/.test(postalCode))
    return ValidationResult.createInvalid(
      "半角数字7桁である必要があります",
      ValidationCode.IllegalFormat
    );

  return ValidationResult.createValid();
}
