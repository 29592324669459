import { ValidationCode, ValidationResult } from ".";

/**
 * 正の整数値をバリデーションする
 * @param {number} val
 * @returns {ValidationResult}
 */
export function validatePositiveInt(val) {
  /** Number型であること */
  if (typeof val !== "number")
    return ValidationResult.createInvalid(
      "Number型である必要があります",
      ValidationCode.IllegalType
    );

  /** 整数であること */
  if (Number(val) % 1 !== 0)
    return ValidationResult.createInvalid(
      "整数である必要があります",
      ValidationCode.IllegalNumType
    );

  /** 1以上であること */
  if (val < 1)
    return ValidationResult.createInvalid(
      "1以上である必要があります",
      ValidationCode.LessThanMin
    );

  return ValidationResult.createValid();
}
