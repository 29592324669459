<template>
  <v-card class="enter-np-payment-dialog">
    <v-card-title
      class="text-subtitle-1 font-weight-bold grey darken-1 white--text justify-center mb-4"
    >
      NP後払い決済入力
    </v-card-title>

    <v-form
      ref="form"
      class="enter-np-payment-dialog__form pa-3"
      lazy-validation
    >
      <div class="enter-np-payment-dialog__form__company-name">
        <label class="enter-np-payment-dialog__form__company-name__label"
          >会社名</label
        >
        <v-text-field
          v-model="npPayment.companyName"
          :rules="companyNameRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__department-name">
        <label class="enter-np-payment-dialog__form__department-name__label"
          >部署名</label
        >
        <v-text-field
          v-model="npPayment.departmentName"
          :rules="departmentNameRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__name-kanji">
        <label class="enter-np-payment-dialog__form__name-kanji__label"
          >氏名</label
        >
        <v-text-field
          v-model="npPayment.nameKanji"
          :rules="nameKanjiRules"
          :validate-on-blur="true"
          placeholder="例) 山田 太郎"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__name-kana">
        <label class="enter-np-payment-dialog__form__name-kana__label"
          >氏名（全角カナ）</label
        >
        <v-text-field
          v-model="npPayment.nameKana"
          :rules="nameKanaRules"
          :validate-on-blur="true"
          placeholder="例) ヤマダ タロウ"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__postal-code">
        <label class="enter-np-payment-dialog__form__postal-code__label"
          >郵便番号</label
        >
        <v-text-field
          v-model="npPayment.postalCode"
          :rules="postalCodeRules"
          :validate-on-blur="true"
          placeholder="例) 1112222"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__address">
        <label class="enter-np-payment-dialog__form__address__label"
          >住所</label
        >
        <v-text-field
          v-model="npPayment.address"
          :rules="addressRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__tel">
        <label class="enter-np-payment-dialog__form__tel__label"
          >電話番号</label
        >
        <v-text-field
          v-model="npPayment.tel"
          :rules="telRules"
          :validate-on-blur="true"
          placeholder="例) 01234567890"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__email">
        <label class="enter-np-payment-dialog__form__email__label"
          >メールアドレス</label
        >
        <v-text-field
          v-model="npPayment.email"
          :rules="emailRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__dest-company-name">
        <label class="enter-np-payment-dialog__form__dest-company-name__label"
          >配送先会社名</label
        >
        <v-text-field
          v-model="npPayment.destCompanyName"
          :rules="destCompanyNameRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__dest-department-name">
        <label
          class="enter-np-payment-dialog__form__dest-department-name__label"
          >配送先部署名</label
        >
        <v-text-field
          v-model="npPayment.destDepartmentName"
          :rules="destDepartmentNameRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__dest-name-kanji">
        <label class="enter-np-payment-dialog__form__dest-name-kanji__label"
          >配送先氏名</label
        >
        <v-text-field
          v-model="npPayment.destNameKanji"
          :rules="destNameKanjiRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__dest-name-kana">
        <label class="enter-np-payment-dialog__form__dest-name-kana__label"
          >配送先カナ氏名</label
        >
        <v-text-field
          v-model="npPayment.destNameKana"
          :rules="destNameKanaRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__dest-postal-code">
        <label class="enter-np-payment-dialog__form__dest-postal-code__label"
          >配送先郵便番号</label
        >
        <v-text-field
          v-model="npPayment.destPostalCode"
          :rules="destPostalCodeRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__dest-address">
        <label class="enter-np-payment-dialog__form__dest-address__label"
          >配送先住所</label
        >
        <v-text-field
          v-model="npPayment.destAddress"
          :rules="destAddressRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__dest-tel">
        <label class="enter-np-payment-dialog__form__dest-tel__label"
          >配送先電話番号</label
        >
        <v-text-field
          v-model="npPayment.destTel"
          :rules="destTelRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__transporter-code">
        <label class="enter-np-payment-dialog__form__transporter-code__label"
          >運送会社コード</label
        >
        <v-text-field
          v-model="npPayment.transporterCode"
          :rules="transporterCodeRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
      <div class="enter-np-payment-dialog__form__slip-no">
        <label class="enter-np-payment-dialog__form__slip-no__label"
          >配送伝票番号</label
        >
        <v-text-field
          v-model="npPayment.slipNo"
          :rules="slipNoRules"
          :validate-on-blur="true"
          outlined
          required
        ></v-text-field>
      </div>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed min-width="100px" outlined @click="back">戻る</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        min-width="100px"
        color="primary font-weight-bold"
        @click="execute"
      >
        決定
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
/** NP後払い入力ダイアログコンポーネント
 * @module JpblEnterNPPaymentDialog
 * @vue-props {EnterNPPaymentDialogCtrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblEnterNPPaymentDialog",
});
</script>

<script setup>
import EnterNPPaymentDialogCtrl from "./controller";
import { validateGeneralName } from "~/utils/validator/general_name";
import { validatePostalCode } from "~/utils/validator/postal_code";
import { validateNameKana } from "~/utils/validator/name_kana";
import { ValidationCode } from "~/utils/validator";
import { validateTel } from "~/utils/validator/tel";
import { validateEmail } from "~/utils/validator/email";
import { PayByNPCommand } from "~/commands/payment";
import SimpleDialog2Ctrl from "~/components/jpbl/UI/dialog/simple_dialog_2/controller";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

const props = defineProps({
  ctrl: {
    type: EnterNPPaymentDialogCtrl,
    required: true,
  },
});

const back = () => dialogAdapter.close();

const paymentCommon = ref(props.ctrl.paymentCommon);
const npPayment = ref(props.ctrl.npPayment);

const command = new PayByNPCommand(paymentCommon.value, npPayment.value);

const execute = async () => {
  const result = await command.execute();

  if (result.successful) {
    dialogAdapter.open(
      new SimpleDialog2Ctrl({
        headerText: "完了",
        buttonPrompt: `${paymentCommon.value.itemName}を購入しました`,
        buttons: {
          OK: {
            click: () => dialogAdapter.closeAll(),
          },
        }
      })
    );
  } else {
    dialogAdapter.open(
      new SimpleDialog2Ctrl({
        headerText: "エラー",
        buttonPrompt: `${paymentCommon.value.itemName}の購入に失敗しました。時間を置いて再度お試しいただくか、管理者にご連絡くださいますようお願い申し上げます。`,
        buttons: {
          OK: {
            click: () => dialogAdapter.closeAll(),
          },
        }
      })
    );
  }
  setTimeout(() => dialogAdapter.closeAll(), 3000);
};

const companyNameRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "会社名は必須です",
];

const departmentNameRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "部署名は必須です",
];

const nameKanjiRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "氏名は必須です",
];

const nameKanaRules = [
  (v) =>
    validateNameKana(v).code !== ValidationCode.EmptyNotAllowed ||
    "カナ氏名は必須です",
  (v) =>
    validateNameKana(v).code !== ValidationCode.IllegalChars ||
    "全角カナまたは空白のみで入力してください",
];

const postalCodeRules = [
  (v) =>
    validatePostalCode(v).code !== ValidationCode.EmptyNotAllowed ||
    "郵便番号は必須です",
  (v) =>
    validatePostalCode(v).code !== ValidationCode.IllegalFormat ||
    "半角数字7桁で入力してください",
];

const addressRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "住所は必須です",
];

const telRules = [
  (v) =>
    validateTel(v).code !== ValidationCode.EmptyNotAllowed ||
    "電話番号は必須です",
  (v) =>
    validateTel(v).code !== ValidationCode.IllegalChars ||
    "半角数字のみで入力してください",
  (v) =>
    validateTel(v).code !== ValidationCode.LessThanMin ||
    "10桁以上で入力してください",
  (v) =>
    validateTel(v).code !== ValidationCode.MoreThanMax ||
    "16桁以下で入力してください",
];

const emailRules = [
  (v) =>
    validateEmail(v).code !== ValidationCode.EmptyNotAllowed ||
    "メールアドレスは必須です",
  (v) =>
    validateEmail(v).code !== ValidationCode.IllegalFormat ||
    "不正なフォーマットです",
  (v) =>
    validateEmail(v).code !== ValidationCode.MoreThanMax ||
    "最大文字数を超えています",
];

const destCompanyNameRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送先会社名は必須です",
];

const destDepartmentNameRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送先部署名は必須です",
];

const destNameKanjiRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送先氏名は必須です",
];

const destNameKanaRules = [
  (v) =>
    validateNameKana(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送先カナ氏名は必須です",
  (v) =>
    validateNameKana(v).code !== ValidationCode.IllegalChars ||
    "全角カナまたは空白のみで入力してください",
];

const destPostalCodeRules = [
  (v) =>
    validatePostalCode(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送先郵便番号は必須です",
  (v) =>
    validatePostalCode(v).code !== ValidationCode.IllegalFormat ||
    "半角数字7桁で入力してください",
];

const destAddressRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送先住所は必須です",
];

const destTelRules = [
  (v) =>
    validateTel(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送先電話番号は必須です",
  (v) =>
    validateTel(v).code !== ValidationCode.IllegalChars ||
    "半角数字のみで入力してください",
  (v) =>
    validateTel(v).code !== ValidationCode.LessThanMin ||
    "10桁以上で入力してください",
  (v) =>
    validateTel(v).code !== ValidationCode.MoreThanMax ||
    "16桁以下で入力してください",
];

const transporterCodeRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "運送会社コードは必須です",
];

const slipNoRules = [
  (v) =>
    validateGeneralName(v).code !== ValidationCode.EmptyNotAllowed ||
    "配送伝票番号は必須です",
];
</script>

<style lang="scss" scoped>
.enter-np-payment-dialog {
  &__form {
    &__label {
      font-size: 14px;
      display: block;
      margin-bottom: 10px
    }

    &__company-name,
    &__department-name,
    &__name-kanji,
    &__name-kana,
    &__postal-code,
    &__address,
    &__tel,
    &__email,
    &__dest-company-name,
    &__dest-department-name,
    &__dest-name-kanji,
    &__dest-name-kana,
    &__dest-postal-code,
    &__dest-address,
    &__dest-tel,
    &__transporter-code,
    &__slip-no {
      &__label {
        @extend .enter-np-payment-dialog__form__label
      }
    }
  }
}
</style>
