import { DialogType } from "../../jpbl/UI/dialog";
import BaseDialogCtrl from "../../jpbl/UI/dialog/base/controller";

export default class SelectPaymentMethodDialogCtrl extends BaseDialogCtrl {
  _type = DialogType.SelectPaymentMethod;

  /**
   * 購入する商品
   * @type {PurchaseItem}
   */
  item
  /**
   * クレカ前回情報支払い
   * @type Boolean
   */
  is_cust_id_purchasable;
  /**
   * クレカ前回情報
   * @type Object
   */
  last_credit_card_info;
  /**
   * @param {PurchaseItem} item - 購入する商品
   */
  constructor(item,is_cust_id_purchasable, last_credit_card_info) {
    super();
    this.item = item;
    this.is_cust_id_purchasable = is_cust_id_purchasable;
    this.last_credit_card_info = last_credit_card_info;
  }
}
