/**
 * 再入禁止化
 * @param fn
 */
export const useGuardReentancy = <T>(fn: Promise<T>): Promise<T> => {
  let processing = false;
  return async (...args) => {
    if (processing) return;
    processing = true;
    const ret = await fn(...args);
    processing = false;
    return ret;
  }
};
