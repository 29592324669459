import { PaymentType } from "~/models/payment";
import { ValidationCode, ValidationResult } from "..";

/**
 * 決済タイプをバリデーションする。
 * @param {string} payType
 * @returns {ValidationResult}
 */
export function validatePayType(payType) {
  const allowedVals = Object.getOwnPropertyNames(PaymentType).map(
    (key) => PaymentType[key]
  );
  if (!allowedVals.includes(payType))
    return ValidationResult.createInvalid(
      `${allowedVals}以外の値は許されません`,
      ValidationCode.NotAllowed
    );

  return ValidationResult.createValid();
}
