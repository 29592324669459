import NPPayment from "~/models/payment/np_payment";
import { DialogType } from "../../jpbl/UI/dialog";
import BaseDialogCtrl from "../../jpbl/UI/dialog/base/controller";
export default class EnterNPPaymentDialogCtrl extends BaseDialogCtrl {
  _type = DialogType.EnterNPPayment;

  /**
   * @type {PaymentCommon}
   */
  paymentCommon

  /**
   * @type {NPPayment}
   */
  npPayment
  
  /**
   * @param {PaymentCommon} paymentCommon 
   * @param {NPPayment} npPayment 
   */
  constructor(paymentCommon, npPayment) {
    super();

    this.paymentCommon = paymentCommon;
    this.npPayment = npPayment;
  }
}
