import { validateGeneralName } from "~/utils/validator/general_name";
import { validatePayMethod } from "~/utils/validator/payment/pay_method";
import { validatePayType } from "~/utils/validator/payment/pay_type";
import { validatePositiveInt } from "~/utils/validator/positive_int";

/** 決済手段を表すEnum */
export const PaymentMethod = {
  Credit: "credit",
//  PayPay: "np",
/*  NP: "nppost",
  Docomo: "docomo",
  SoftBank: "softbank",
  AUOne: "auone",
  Webmoney: "webmoney",
  PayPay: "np",
  WebCvs: "webcvs",*/
};

/** 決済タイプを表すEnum */
export const PaymentType = {
  Token: "token",
  Normal: "normal",
};

/** 決済手段に関らず共通な情報を保持するクラス */
export class PaymentCommon {
  /**
   * @param {{
   *  payMethod: string,
   *  payType: string,
   *  amount: number,
   *  tax: number,
   *  itemId: string,
   *  itemName: string,
   *  token: string,
   *  tokenKey: string,
   * }} params 
   */
  constructor(params) {
    this.payMethod = params.payMethod;
    this.payType = params.payType;
    this.amount = params.amount;
    this.tax = params.tax;
    this.itemId = params.itemId;
    this.itemName = params.itemName;
    this.token = params.token;
    this.tokenKey = params.tokenKey;
  }

  /**
   * 決済手段
   * @type {string} - PaymentMethodに列挙されている値
   */
  payMethod = PaymentMethod.Credit;

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validatePayMethod() {
    const result = validatePayMethod(this.payMethod);
    if (!result.isValid) throw result.toError();
  }

  /**
   * 決済タイプ
   * @type {string}
   * @todo DA06003-427 セキュリティのため、クレカの場合はトークン型のみとする
   */
  payType = PaymentType.Normal;

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validatePayType() {
    const result = validatePayType(this.payType);
    if (!result.isValid) throw result.toError();
  }

  /**
   * @type {number}
   */
  amount = 0;

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateAmount() {
    const result = validatePositiveInt(this.amount);
    if (!result.isValid) throw result.toError();
  }

  /**
   * @type {number}
   */
  tax = 0;

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateTax() {
    const result = validatePositiveInt(this.tax);
    if (!result.isValid) throw result.toError();
  }

  /**
   * @type {string}
   */
  itemId = "";

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateItemId() {
    const result = validateGeneralName(this.itemId);
    if (!result.isValid) throw result.toError();
  }

  /**
   * @type {string}
   */
  itemName = "";

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateItemName() {
    const result = validateGeneralName(this.itemName);
    if (!result.isValid) throw result.toError();
  }

  /**
   * @type {string}
   */
  token = "";
  
  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateToken() {
    const result = validateGeneralName(this.token);
    if (!result.isValid) throw result.toError();
  }

  /**
   * @type {string}
   */
  tokenKey = "";

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateTokenKey() {
    const result = validateGeneralName(this.tokenKey);
    if (!result.isValid) throw result.toError();
  }
}
