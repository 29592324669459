import { ValidationCode, ValidationResult } from ".";

/**
 * 名前一般をバリデーションする。例：氏名、会社名、部署名
 * @param {string} name
 * @param {boolean} isOptional - 必須の値であればfalse、任意であればtrue
 * @returns {ValidationResult}
 */
export function validateGeneralName(name, isOptional = false) {
  /** 空の場合 */
  if (!name)
    return isOptional
      ? ValidationResult.createValid()
      : ValidationResult.createInvalid(
          "空です",
          ValidationCode.EmptyNotAllowed
        );

  return ValidationResult.createValid();
}
