<template>
  <v-bottom-navigation
    v-if="shouldDisplayFooter"
    bgColor="black"
    class="footer"
    :height="FOOTER_HEIGHT_PX"
    :style="{'z-index': 110}"
    app
  >
    <!-- @todo メニューボタンをコンポーネント化する-->
    <v-btn value="home" class="footer__btn" @click="jumpTo('/')">
      <img
        src="~assets/jpbl/themes/sns8/sp/img/menu/footer/home.png"
        class="footer__btn__img"
      />
    </v-btn>
    <v-btn value="mission" class="footer__btn" @click="jumpTo('/mission')">
      <img
        src="~assets/jpbl/themes/sns8/sp/img/menu/footer/mission.png"
        class="footer__btn__img"
      />
    </v-btn>
    <v-btn value="holder" class="footer__btn" @click="jumpTo('/folder')">
      <img
        src="~assets/jpbl/themes/sns8/sp/img/menu/footer/holder.png"
        class="footer__btn__img"
      />
    </v-btn>
    <v-btn value="gacha" class="footer__btn" @click="jumpTo('/gacha')">
      <img
        :src="`${config.public.SNS_PATH}sp/img/layout/blg/icons/icon_cardpack.png`"
        class="footer__btn__img"
      />
      <span class="footer__btn__text">ガチャ</span>
    </v-btn>
    <v-btn value="menu" class="footer__btn" @click="openMenu">
      <img
        src="~assets/jpbl/themes/sns8/sp/img/menu/footer/menu.png"
        class="footer__btn__img"
      />
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
/** フッターコンポーネント
 * @module Footer
 */
export default defineNuxtComponent({
  name: "jpblFooter",
});
</script>

<script setup>
import MenuPanelDialogCtrl from "../UI/dialog/menu_panel/controller";
import { FOOTER_HEIGHT_PX } from "~/constants";
import { getNotice } from "~/helpers/UI/notice";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

const config = useRuntimeConfig();
const { $ua, $router } = useNuxtApp();
const shouldDisplayFooter = !$ua.isApp();
const jumpTo = (path) => $router.push(path);
const openMenu = () => dialogAdapter.open(new MenuPanelDialogCtrl());
setInterval(async () => {
  await getNotice();
}, 10 * 1000);
</script>

<style lang="scss" scoped>
.footer {
  z-index: 110;
  &__btn {
    &__text {
      color: white;
      font-size: 8px;
    }

    &__img {
      height: 40px;
      margin-bottom: 3px;
    }
  }
}
</style>
