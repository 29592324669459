/**
 * 決済関連のコマンドを集約するファイル
 */

import ConfigurationBuilder from "./api/configuration_builder";
import { PaymentApi } from "~/plugins/openapi/api";
import { PaymentCommon, PaymentMethod, PaymentType } from "~/models/payment";
import CreditCard from "~/models/payment/credit_card";
import { ValidationResult } from "~/utils/validator";
import NPPayment from "~/models/payment/np_payment";
import LinkPayment from "~/models/payment/link/index";

/**
 * クレジットカード決済（API型）を実行するコマンド
 */
export class PayByCreditCardCommand {
  /**
   * @param {PaymentCommon} paymentCommon
   * @param {CreditCard} creditCard
   * @param {PaymentApi} api - 決済関連のAPI通信を担うオブジェクト
   */
  constructor(
    paymentCommon,
    creditCard,
    api = new PaymentApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.paymentCommon = paymentCommon;
    this.creditCard = creditCard;
    this.api = api;
  }

  /**
   * @returns {Promise<{successful: boolean>}
   */
  execute() {
    try {
      const {
        payMethod,
        payType,
        itemId,
        itemName,
        amount,
        tax,
        token,
        tokenKey,
        custManage,
        isCustIdPurchase,
      } = PayByCreditCardCommand._buildPayload(this.paymentCommon, this.creditCard);
      return this.api
        .ajaxSbpaymentApiPost(
          undefined,
          payMethod,
          payType,
          itemId,
          itemName,
          amount,
          tax,
          token,
          tokenKey,
          custManage,
          isCustIdPurchase,
        )
        .then(res => this._parseResponseData(res))
        .catch(error => this._parseResponseData(error.response));
    } catch (error) {
      console.error(error);
      return { successful: false };
    }
  }

  /**
   * レスポンスデータをパースして、（コマンドクラスの）クライアントに返すデータを構築する。
   *
   * @param {AxiosResponse} res - レスポンスオブジェクト
   * @returns {{successful: boolean}
   */
  _parseResponseData(res) {
    return res && res.data && res.data.code
      ? {
        successful: res.data.code === PayByCreditCardCommand._SUCCESSFUL_CODE,
      }
      : {
        successful: false,
      };
  }

  /**
   * 成功時に返却されるコード
   * @type {number}
   */
  static _SUCCESSFUL_CODE = 1;

  /**
   * 入力データをバリデーションしてから、リクエストボディのペイロードを生成する。
   * @param {PaymentCommon} paymentCommon
   * @param {CreditCard} creditCard
   * @returns {{
   *  payMethod: string,
   *  payType: string,
   *  itemId: string,
   *  itemName: string,
   *  amount: number,
   *  tax: number,
   *  cardNo: string,
   *  cardExpire: string,
   *  cardCvc: string,
   *  token: string,
   *  tokenKey: string,
   * }}
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  static _buildPayload(paymentCommon, creditCard) {
    try {
      paymentCommon.validatePayMethod();
      paymentCommon.validatePayType();
      paymentCommon.validateItemId();
      paymentCommon.validateItemName();
      paymentCommon.validateAmount();
      paymentCommon.validateTax();
      if (!creditCard.isCustIdPurchase) {
          creditCard.validateNumber();
          creditCard.validateMonthStr();
          creditCard.validateYear();
          creditCard.validateSecurityCode();
          creditCard.validateToken(true); // @todo DA06003-427 トークン型を利用できるようになったら`isOptional`引数を削除して、必須値としてバリデーションする
          creditCard.validateTokenKey(true); // @todo DA06003-427 トークン型を利用できるようになったら`isOptional`引数を削除して、必須値としてバリデーションする
      }
      /** 決済手段がクレジットカードであること */
      if (paymentCommon.payMethod !== PaymentMethod.Credit)
        throw ValidationResult.createInvalid("決済手段はクレジットカードである必要があります").toError();

      /** 
       * 決済タイプがトークン型であること
       * @todo DA06003-427 トークン型を利用できるようになったらこのバリデーションを追加する
       */
      // if (paymentCommon.payType !== PaymentType.Token)
      //   throw ValidationResult.createInvalid("決済タイプはトークン型である必要があります").toError();

      return {
        payMethod: paymentCommon.payMethod,
        payType: paymentCommon.payType,
        itemId: paymentCommon.itemId,
        itemName: paymentCommon.itemName,
        amount: paymentCommon.amount,
        tax: paymentCommon.tax,
        cardNo: creditCard.number,
        cardExpire: `${creditCard.year}${creditCard.monthStr}`,
        cardCvc: creditCard.securityCode,
        token: creditCard.token,
        tokenKey: creditCard.tokenKey,
        custManage: creditCard.custManage,
        isCustIdPurchase: creditCard.isCustIdPurchase,
      }
    } catch (error) {
      throw error;
    }
  }
}

/**
 * NP後払い決済（API型）を実行するコマンド
 */
 export class PayByNPCommand {
  /**
   * @param {PaymentCommon} paymentCommon
   * @param {NPPayment} npPayment
   * @param {PaymentApi} api - 決済関連のAPI通信を担うオブジェクト
   */
  constructor(
    paymentCommon,
    npPayment,
    api = new PaymentApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.paymentCommon = paymentCommon;
    this.npPayment = npPayment;
    this.api = api;
  }

  /**
   * @returns {Promise<{successful: boolean>}
   */
  execute() {
    try {
      const {
        payMethod,
        payType,
        itemId,
        itemName,
        amount,
        tax,
        companyName,
        department,
        customerName,
        customerNameKana,
        zip,
        address,
        tel,
        email,
        destCompanyName,
        destDepartment,
        destCustomerName,
        destCustomerNameKana,
        destZip,
        destAddress,
        destTel,
        pdCompanyCode,
        slipNo,
      } = PayByNPCommand._buildPayload(this.paymentCommon, this.npPayment);
      return this.api
        .ajaxSbpaymentApiPost(
          undefined,
          payMethod,
          payType,
          itemId,
          itemName,
          amount,
          tax,
          undefined,
          undefined,
          companyName,
          department,
          customerName,
          customerNameKana,
          zip,
          address,
          tel,
          email,
          destCompanyName,
          destDepartment,
          destCustomerName,
          destCustomerNameKana,
          destZip,
          destAddress,
          destTel,
          pdCompanyCode,
          slipNo
        )
        .then(res => this._parseResponseData(res))
        .catch(error => this._parseResponseData(error.response));
    } catch (error) {
      console.error(error);
      return { successful: false };
    }
  }

  /**
   * レスポンスデータをパースして、（コマンドクラスの）クライアントに返すデータを構築する。
   *
   * @param {AxiosResponse} res - レスポンスオブジェクト
   * @returns {{successful: boolean}
   */
  _parseResponseData(res) {
    return res && res.data && res.data.code
      ? {
        successful: res.data.code === PayByNPCommand._SUCCESSFUL_CODE,
      }
      : {
        successful: false,
      };
  }

  /**
   * 成功時に返却されるコード
   * @type {number}
   */
  static _SUCCESSFUL_CODE = 1;

  /**
   * 入力データをバリデーションしてから、リクエストボディのペイロードを生成する。
   * @param {PaymentCommon} paymentCommon
   * @param {NPPayment} npPayment
   * @returns {{
   *  payMethod: string,
   *  payType: string,
   *  itemId: string,
   *  itemName: string,
   *  amount: number,
   *  tax: number,
   *  companyName: string
   *  department: string
   *  customerName: string
   *  customerNameKana: string
   *  zip: string
   *  address: string
   *  tel: string
   *  email: string
   *  destCompanyName: string
   *  destDepartment: string
   *  destCustomerName: string
   *  destCustomerNameKana: string
   *  destZip: string
   *  destAddress: string
   *  destTel: string
   *  pdCompanyCode: string
   *  slipNo: string
   * }}
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  static _buildPayload(paymentCommon, npPayment) {
    try {
      paymentCommon.validatePayMethod();
      paymentCommon.validatePayType();
      paymentCommon.validateItemId();
      paymentCommon.validateItemName();
      paymentCommon.validateAmount();
      paymentCommon.validateTax();
      npPayment.validateCompanyName();
      npPayment.validateDepartmentName();
      npPayment.validateNameKanji();
      npPayment.validateNameKana();
      npPayment.validatePostalCode();
      npPayment.validateAddress();
      npPayment.validateTel();
      npPayment.validateEmail();
      npPayment.validateDestCompanyName();
      npPayment.validateDestDepartmentName();
      npPayment.validateDestNameKanji();
      npPayment.validateDestNameKana();
      npPayment.validateDestPostalCode();
      npPayment.validateDestAddress();
      npPayment.validateDestTel();
      npPayment.validateTransporterCode();
      npPayment.validateSlipNo();

      /** 決済手段がNP後払いであること */
      if (paymentCommon.payMethod !== PaymentMethod.NP)
        throw ValidationResult.createInvalid("決済手段はNP後払いである必要があります").toError();

      /** 決済タイプがNormal（非トークン型）であること */
      if (paymentCommon.payType !== PaymentType.Normal)
        throw ValidationResult.createInvalid("決済タイプはNormal（非トークン型）である必要があります").toError();

      return {
        payMethod: paymentCommon.payMethod,
        payType: paymentCommon.payType,
        itemId: paymentCommon.itemId,
        itemName: paymentCommon.itemName,
        amount: paymentCommon.amount,
        tax: paymentCommon.tax,
        companyName: npPayment.companyName,
        department: npPayment.departmentName,
        customerName: npPayment.nameKanji,
        customerNameKana: npPayment.nameKana,
        zip: npPayment.postalCode,
        address: npPayment.address,
        tel: npPayment.tel,
        email: npPayment.email,
        destCompanyName: npPayment.destCompanyName,
        destDepartment: npPayment.destDepartmentName,
        destCustomerName: npPayment.destNameKanji,
        destCustomerNameKana: npPayment.destNameKana,
        destZip: npPayment.destPostalCode,
        destAddress: npPayment.destAddress,
        destTel: npPayment.destTel,
        pdCompanyCode: npPayment.transporterCode,
        slipNo: npPayment.slipNo,
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

/**
 * リンク型決済用のリンクを取得するコマンド
 */
 export class GetPaymentLinkCommand {
  /**
   * @param {PaymentCommon} paymentCommon
   * @param {LinkPayment} linkPayment
   * @param {PaymentApi} api - 決済関連のAPI通信を担うオブジェクト
   */
  constructor(
    paymentCommon,
    linkPayment,
    api = new PaymentApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.paymentCommon = paymentCommon;
    this.linkPayment = linkPayment;
    this.api = api;
  }

  /**
   * @returns {Promise<{successful: boolean, serverUrl: ?string, params: ?{[idx: number]: {name: string, value: any}}>}
   */
  execute() {
    try {
      const {
        payMethod,
        itemId,
        itemName,
        amount,
        tax
      } = GetPaymentLinkCommand._buildPayload(this.paymentCommon, this.linkPayment);

      return this.api
        .ajaxSbpaymentLinkPost(
          undefined,
          payMethod,
          itemId,
          itemName,
          amount,
          tax
        )
        .then(res => this._parseResponseData(res))
        .catch(error => this._parseResponseData(error.response));
    } catch (error) {
      console.error(error);
      return { successful: false };
    }
  }

  /**
   * レスポンスデータをパースして、（コマンドクラスの）クライアントに返すデータを構築する。
   *
   * @param {AxiosResponse} res - レスポンスオブジェクト
   * @returns {{successful: boolean, serverUrl: ?string, params: ?{[idx: number]: {name: string, value: any}}}
   */
  _parseResponseData(res) {
    return res && res.data && res.data.code
      ? {
        successful: res.data.code === GetPaymentLinkCommand._SUCCESSFUL_CODE,
        serverUrl: res.data.serverUrl,
        params: res.data.params,
      }
      : {
        successful: false,
        serverUrl: null,
        params: null,
      };
  }

  /**
   * 成功時に返却されるコード
   * @type {number}
   */
  static _SUCCESSFUL_CODE = 1;

  /**
   * 入力データをバリデーションしてから、リクエストボディのペイロードを生成する。
   * @param {PaymentCommon} paymentCommon
   * @param {LinkPayment} linkPayment
   * @returns {{
   *  payMethod: string,
   *  payType: string,
   *  itemId: string,
   *  itemName: string,
   *  amount: number,
   *  tax: number
   * }}
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  static _buildPayload(paymentCommon, linkPayment) {
    try {
      paymentCommon.validatePayMethod();
      paymentCommon.validateItemId();
      paymentCommon.validateItemName();
      paymentCommon.validateAmount();
      paymentCommon.validateTax();

      return {
        payMethod: paymentCommon.payMethod,
        itemId: paymentCommon.itemId,
        itemName: paymentCommon.itemName,
        amount: paymentCommon.amount,
        tax: paymentCommon.tax
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
