<template>
  <div class="information_dialog">
    <div class="information_dialog__news_box">
      <div class="information_dialog__news_box__header news_header">
        <button
          v-if="shouldShowDetail"
          class="information_dialog__news_box__header__back"
          @click="closeDetail"
        >
          <img :src="`${config.public.SNS_PATH}sp/img/layout/back_btn.png`" />
        </button>
        <h1 class="information_dialog__news_box__header__title">
          お知らせ<br /><span class="news_ttl_en">NEWS</span>
        </h1>
        <button
          class="information_dialog__news_box__header__close"
          @click="closeNews"
        >
          <img :src="`${config.public.SNS_PATH}/sp/img/layout/close_btn.png`" />
        </button>
      </div>
      <ul
        v-if="informations.length > 0"
        class="information_dialog__news_box__body news_list"
      >
        <template v-for="(row, index) in informations" :key="index">
          <li v-if="!shouldShowDetail || isSelected(index)">
            <div v-if="shouldShowDetail" class="nav">
              <p
                v-if="index > 0"
                class="news_prev_info"
                @click="showNews(index - 1)"
              >
                ＜ 前のお知らせ
              </p>
              <p
                v-if="index < informations.length - 1"
                class="news_next_info"
                @click="showNews(index + 1)"
              >
                次のお知らせ ＞
              </p>
            </div>
            <div v-else class="news_next">
              <img :src="`${config.public.SNS_PATH}sp/img/layout/next_arrow.png`" />
            </div>
            <p
              class="news_category"
              :style="`color:${row.fore_color};background-color:${row.back_color};`"
            >
              {{ row.category_name }}
            </p>
            <p class="news_date">掲載:{{ row.start_at }}～{{ row.end_at }}</p>
            <p v-if="!row.is_readed" class="news_unread">未読</p>
            <br />
            <p v-if="row.images" class="news_banner" @click="showNews(index)">
              <img :src="row.images" />
            </p>
            <p
              class="news_title"
              @click="showNews(index)"
              v-html="$sanitize(row.title)"
            ></p>
            <div
              v-if="isSelected(index)"
              class="news_text"
              v-html="row.body"
            ></div>
          </li>
        </template>
      </ul>
      <ul v-else class="news_list">
        <li class="none">
          <div class="news_text">お知らせはありません</div>
        </li>
      </ul>
      <div class="news_box_footer cf"></div>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  name: "informationComponent",
});
</script>

<script setup>
import {
  GetInformationCommand,
  MarkInformationAsReadCommand,
} from "~~/commands/information";
import InformationDialogCtrl from "./controller";
import { hide_news_today } from "~/utils/modules/sp/info-module";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

const props = defineProps({
  ctrl: {
      type: InformationDialogCtrl,
      required: true,
    },
});
const config = useRuntimeConfig();
const { $sanitize } = useNuxtApp();
const { $router } = useNuxtApp();
const { data } = useLazyAsyncData("page", () =>
  new GetInformationCommand().execute()
);
const informations = ref([]);

let selected = ref(null); // 選択（詳細表示）されているアイテムのインデックスを保持する。未選択時にはnull。
const isSelected = (index) => index === selected.value;
const shouldShowDetail = computed(() => selected.value !== null);

watch(data, (newData) => {
  if (newData && newData.informations) {
    let i;
    for (i = 0; i < newData.informations.length; i++) {
      const info = newData.informations[i];
      info.body = parse(info.body);
      informations.value.push(info);
    }
  }
});

/**
 * 詳細を開く
 * @param {number} - 詳細を開きたいアイテムのインデックス
 */
async function showNews(index) {
  selected.value = index;
  setTimeout(() => {
    Array.from(document.querySelectorAll("page-link"), (tag) => {
      tag.addEventListener("click", (event) => {
        let link = tag.getAttribute("href");
        let onclick = tag.getAttribute("onClick");
        if (onclick && onclick.indexOf('openExternal')) {
          let sp = onclick.split("'");
          window.open(sp[1], '_blank', 'noopener');
          return;
        }
        let tmp = link.split("/");
        tmp.shift();
        tmp[0] = ""; //jpblを削除する
        let path = tmp.join("/");
        dialogAdapter.close();
        $router.push({ path: path });
      });
    });
  }, 50);

  if (parseInt(informations.value[index].is_readed) === 0) {
    try {
      await new MarkInformationAsReadCommand(
        informations.value[index].id
      ).execute();
      informations.value[index].is_readed = 1;
    } catch (error) {
      console.error(error);
    }
  }
}

/**
 * 詳細を閉じる
 */
function closeDetail() {
  selected.value = null;
}

/**
 * ダイアログを閉じる
 */
function closeNews() {
  dialogAdapter.close();
}

onMounted(() => {
  if (!props.ctrl.display_checkbox) {
    $(".news_box .today_check").css("display", "none");
    // 閉じるボタンをセンター表示
    var closeBtn = $(".news_box .closeBtn");
    var closeBtnA = $(".news_box .closeBtn a");
    if (closeBtn.length && closeBtnA.length) {
      closeBtn.width("100%");
      closeBtnA.css("margin-left", "auto");
      closeBtnA.css("margin-right", "auto");
    }
  }

  hide_news_today();
});
const parse = (html) => {
  let ret = html.replaceAll('<A ', '<page-link ').replaceAll('</A>', '</page-link>');
  ret = ret.replaceAll('<a ', '<page-link ').replaceAll('</a>', '</page-link>');
  /*let ret = $sanitize(html, {
    transformTags: {
      a: "page-link",
    },
  });*/
  return ret;
};
</script>

<style lang="scss" scoped>
$header-height: 55px;
$btn-width: 34px;

.information_dialog {
  height: 100%;

  &__news_box {
    height: 100%;
    background-color: #333;

    &__header {
      height: $header-height;
      padding: 10px 0;
      position: relative;
      background-color: #4b4b4b;
      line-height: 1.4;

      &__back {
        position: absolute;
        width: $btn-width;
        top: 0;
        bottom: 0;
        left: 18px;

        img {
          width: 100%;
        }
      }

      &__title {
        font-size: 15px;
        letter-spacing: 0.05em;
        color: #fff;
        padding: 0;
        text-align: center;
      }

      &__close {
        position: absolute;
        width: $btn-width;
        top: 0;
        bottom: 0;
        right: 10px;

        img {
          width: 100%;
        }
      }
    }

    &__body {
      height: calc(100% - #{$header-height});
      padding: 10px;
      overflow-y: auto;
    }
  }
}

.nav {
  height: 28px;
  margin-bottom: 10px;
}

p.news_date {
  margin-bottom: 0;
}
</style>
