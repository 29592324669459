<template>
  <div>
    <span v-if="tapFlg" class="ripple_effect" :style="tapPosition"></span>
  </div>
</template>

<script>
/** タップエフェクトコンポーネント <br>
 * @module  Tap
 * @vue-data {String} tapFlg - タップエフェクトの表示フラグ
 * @vue-data {String} tapPosition - タップエフェクトの位置、初期値は{}、tapEffectメソッドで取得
 * @vue-event {String} toggleTap - タップエフェクト表示の切り替え
 * @vue-event {String} tapEffect - タップエフェクトをアニメーションで表示し、その後非表示にするメソッド
 */
export default defineNuxtComponent({
  data() {
    return {
      tapFlg: false,
      tapPosition: {},
    };
  },
  methods: {
    toggleTap() {
      this.tapFlg = !this.tapFlg;
    },
    tapEffect(e) {
      /** エフェクトを表示 */
      this.toggleTap();
      /** タップした位置を取得 */
      this.tapPosition = {
        top: e.pageY - 25 + "px",
        left: e.pageX - 25 + "px",
      };
      /** 0.75秒後に非表示に */
      setTimeout(this.toggleTap, 750);
    },
  },
});
</script>

<style lang="scss" scoped>
div {
  .ripple_effect {
    width: 50px;
    height: 50px;
    position: fixed;
    border-radius: 100%;
    pointer-events: none;
    transform: scale(0);
    opacity: 0;
    z-index: 100000;
    background: white;
    animation: ripple 0.75s ease-out;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
  }

  to {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
