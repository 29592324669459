export var ImageLoadWorker = {
    m_workers: [],
    m_workerIndex: 0,
    m_request_count: [],
    m_complate_count: [],
    m_callbackComplate: [],
    m_timer_id: [],
    init_worker: function() {
        var worker_count = 4;
        if (this.m_workers.length > 0 ) return;
        for (i = 0; i < worker_count; i++){
            this.m_workers[i] = new Worker('/themes/common/sp/js/image_load_worker_wk.js');
            this.m_workers[i].addEventListener('message', (event) => {
              switch (event.data.action) {
              case 'returnCallback':
                this.onCacheImageLoad(event.data.message);
                break;

              case 'callbackHandler':
                window.webkit.messageHandlers.callbackHandler.postMessage(event.data.message);
                break;
              }
            });
        }
    },
    load_image_api: function(id, key, contents_type, update_key, img_url, group) {
        if (typeof group === 'undefined') group = 'main';
        this.init_worker();
        var param = {};
        param['group'] = group;
        param['element_id'] = id;
        param['key'] = key;
        param['content_type'] = contents_type;
        param['update_key'] = update_key;
        param['src_url'] = img_url;
        this.m_workers[this.m_workerIndex].postMessage(JSON.stringify(param));
        this.m_workerIndex += 1;
        if (this.m_workerIndex >= this.m_workers.length) this.m_workerIndex = 0;
 
    },
    onCacheImageLoad: function(data) {
        var ret = JSON.parse(data);
        if (ret.result == '') {
            $(ret.element_id).attr('src', ret.src_url);
        }
        else {
            $(ret.element_id).attr('src', ret.result);
        }
        if (this.m_callbackComplate[ret.group]) {
            this.m_complate_count[ret.group] += 1;
            //console.log('comp:' + this.m_complate_count + '/' + this.m_request_count);
            if ( this.m_complate_count[ret.group] >= this.m_request_count[ret.group]){
                if (this.m_timer_id[ret.group] != null ) window.clearTimeout(this.m_timer_id[ret.group]);
                this.m_timer_id[ret.group] = null
                this.m_callbackComplate[ret.group](true);
            }
        }
    },
    setonLoadComplate: function(req_count, timeout, callbackComplate, group) {
        if (typeof group === 'undefined') group = 'main';
        this.m_request_count[group] = req_count;
        this.m_complate_count[group] = 0;
        this.m_callbackComplate[group] = callbackComplate;
        if (this.m_timer_id[group] != null) window.clearTimeout(this.m_timer_id[group]);
        this.m_timer_id[group] = window.setTimeout(callbackComplate, timeout);
    }
}

        //--------------------------------------------------------------//
        // public
        //--------------------------------------------------------------//

        /// uuidを取得
        /// onReturnCallback(success, uuid)
        /// ※iosは動かない
        export function cf_uuid_get(onReturnCallback) {
            var fncUrl = "https://client-func/uuid/get";
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success, responseText);
            });
        }

        /// uuidを削除
        /// onReturnCallback(success)
        export function cf_uuid_clear(onReturnCallback) {
            var fncUrl = "https://client-func/uuid/clear";
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }

        /// タブバーを表示
        /// onReturnCallback(success)
        export function cf_footer_show(onReturnCallback, animate) {
            var fncUrl = "https://client-func/footer/show?animate=" + (!animate ? "no" : "yes");
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }

        /// タブバーを非表示
        /// onReturnCallback(success)
        export function cf_footer_hide(onReturnCallback, animate) {
            var fncUrl = "https://client-func/footer/hide?animate=" + (!animate ? "no" : "yes");
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }

        /// タブバーのボタンにバッジを表示
        /// onReturnCallback(success)
        export function cf_footer_setBadge(onReturnCallback, index, value) {
            var fncUrl = "https://client-func/footer/set_badge?index=" + index + "&value=" + value;
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }

        /// 指定のプロダクトを購入
        /// onReturnCallback(success)
        export function cf_store_payment(onReturnCallback, productId) {
            var fncUrl = "https://client-func/store/payment?product_id=" + productId;
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }

        /// プッシュ通知開始確認 (※Androidでは何も表示されない、iOSでも設定で既に許可されてる場合は表示されない)
        /// onReturnCallback(success)
        export function cf_app_confirmPushNotification(onReturnCallback) {
            var fncUrl = "https://client-func/app/confirm_push_notification";
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }

        /// 外部URLを開く
        /// onReturnCallback(success)
        export function cf_app_openUrl(onReturnCallback, url, cantOpenMsg) {
//            url = encodeURIComponent(url);
            cantOpenMsg =  encodeURIComponent(cantOpenMsg);
            var fncUrl = "https://client-func/app/open_url?url=" + url + "&cant_open_msg=" + cantOpenMsg;
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }
        
        /// セッションが確率しクライアントからのAPIコールが出来ることをクライアントに伝える
        /// onReturnCallback(success)
        export function cf_app_canCallApi(onReturnCallback, isCan) {
            var fncUrl = "https://client-func/app/can_call_api?can=" + (isCan ? "yes" : "no");
            _cf_callClientFunction(fncUrl, function (success, responseText) {
                onReturnCallback(success);
            });
        }

        /// iPhoneのJSブリッジ用
        export function cacheImage(img, group, element_id, src_url) {
          ImageLoadWorker.onCacheImageLoad(JSON.stringify({
            "group": group,
            "element_id": element_id,
            "result": img,
            "src_url": src_url
          }));
        }

        //--------------------------------------------------------------//
        // private
        //--------------------------------------------------------------//

        // NOTE キューに詰めて順番を保証するかも
        function _cf_callClientFunction(fncUrl, onReturnCallback) {
            try{
              if (navigator.userAgent.indexOf("Android") == -1) {
                window.webkit.messageHandlers.callbackHandler.postMessage(fncUrl);
              } else {
                var xhr = new XMLHttpRequest();
                xhr.open('POST', fncUrl, true);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == 4) {
                        if (xhr.status == 200) {
                            onReturnCallback(true, xhr.responseText);
                        }
                        else {
                            onReturnCallback(false, "");
                        }
                    }
                }
                xhr.onerror = function (e) {
//                    console.log("ERROR call clinet function: ");
                    onReturnCallback(false, "");
                }
                xhr.send(null);
              }
            }
            catch(e){

            }
        }

        export function cf_app_loadImageApi(onReturnCallback, element_id, key, content_type, update_key, src_url, group) {
            if (typeof group === 'undefined') group = 'main';
            ImageLoadWorker.load_image_api(element_id, key, content_type, update_key, src_url, group);
        }
        export function cf_app_loadImageSetOnLoadComplate(req_count, timeout, onReturnCallback, group) {
            if (typeof group === 'undefined') group = 'main';
            ImageLoadWorker.setonLoadComplate(req_count, timeout, onReturnCallback, group);
        }
        export function cf_cacheImageLoaded(element_id, data, src_url) {
            if (data == '') {
                $(element_id).attr('src', src_url);
            }
            else {
                $(element_id).attr('src', data);
            }
        }
