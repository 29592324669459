<template>
  <VitePwaManifest  />
  <v-app ref="wrapper" class="bg normal no_touch" v-bind:class="[hideMenu ? 'no_header' : !isLoggedIn ? 'not_login' : '']">
    <div class="no_touch" style="height: 100%" @click="tap($event)">
      <JpblHeader v-if="!hideMenu && !pending" class="no_touch" />
      <v-main v-if="!pending" class="no_touch scrollable_v-main" scrollable>
        <slot />
      </v-main>
      <div
        v-if="!hideFooter && !hideFloatingBtn"
        :class="`bottom_btn${is_ios ? '' : ''}`"
      >
        <JpblBackBtn />
        <!--<JpblMenuBtn v-if="!hideMenuBtn" />-->
      </div>
      <JpblFooter v-if="!hideFooter" />
      <JpblBaseDialog />
      <!--タップエフェクトのコンポーネント-->
      <Tap ref="refTap" />
    </div>
  </v-app>
</template>

<script>
/** デフォルト処理記述 <br>
 * @module  default
 * @vue-event tap - 画面タップ時のエフェクト
 */
export default defineNuxtComponent({
});
</script>

<script setup>
import Tap from "@/components/core/UI/Tap";
import JpblLogin from "@/components/jpbl/UI/login.vue";
import JpblHeader from "@/components/jpbl/layouts/header/index.vue";
import JpblBackBtn from "@/components/jpbl/layouts/back_btn.vue";
import JpblMenuBtn from "@/components/jpbl/layouts/menu_btn.vue";
import JpblFooter from "@/components/jpbl/layouts/footer.vue";
import JpblBaseDialog from "@/components/jpbl/UI/dialog/base/index.vue";
import ClientFunctionsStrategy from "~/utils/modules/sp/client_functions_strategy.js";
import { useHeaderData } from "~/composables/useHeaderData";
import CardMaster from "@/masters/cards";
import SimpleDialog2Ctrl from "~/components/jpbl/UI/dialog/simple_dialog_2/controller";
import { LOGIN_UUID_SESSION_STORAGE_KEYNAME } from "~/constants";
import { bottom } from "/utils/modules/sp/jquery.bottom-module.js";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
import { until } from "@vueuse/core";
const { dialogAdapter } = useDialogAdapter();
bottom($);
const {
  $router,
} = useNuxtApp();

const route = useRoute();
const { $ua } = useNuxtApp();
const routesToHideMenu = ["gacha-result_payment", "forecast-exchange-effect", "login"];
const routesToHideMenuBtn = ["folder"];
const routesToHideFloatingBtn = ["gacha-result_payment", "forecast-exchange-effect"];
const routesGuestPages = ["login", "tutorial-navi", "login-complete", "user_policy", "applogin", "applogin-complete", "serial"];
const routesRegexGuestPages = ["^page.*", "^news.*", "coupon.*", "lottery.*"];
const routesWithHeaderGuestPages = ["user-mypage-uid"];
const isWithHeaderGuestPage = computed(() => routesWithHeaderGuestPages.includes(route.name));
const isGuestPage = computed(() => checkRegexGuestPage(route.name, routesRegexGuestPages) || routesGuestPages.includes(route.name) || isWithHeaderGuestPage.value);
const hideMenu = computed(() => routesToHideMenu.includes(route.name) || ((isGuestPage.value && !isWithHeaderGuestPage.value) && !isLoggedIn.value));
const hideFooter = computed(() => hideMenu.value || !isLoggedIn.value);
const hideMenuBtn = computed(() => routesToHideMenuBtn.includes(route.name) || (isGuestPage.value && !isLoggedIn.value));
const hideFloatingBtn = computed(() => routesToHideFloatingBtn.includes(route.name) || (isGuestPage.value && !isLoggedIn.value));

const checkRegexGuestPage = (route_name, guest_urls) => {
  let ret = false;
  for(let url of guest_urls) {
    let regex_result = new RegExp(url).exec(route_name);
    if (regex_result != null) {
      ret = true;
      break;
    }
  }
  return ret;
}

const is_ios = $ua.isIOS() && $ua.isSafari() && !$ua.isStandAlone(); //IOSでスタンドアローン起動していない場合はアドレスバーが邪魔するので調整する

const { data, isLoggedIn, reloadHeader, pending: headerPending } = useHeaderData();
// ヘッダ情報取得が必要
const isNeedHeaderData = computed(() => !isGuestPage.value || isWithHeaderGuestPage.value);
// ヘッダ情報取得が必要で未取得の場合はペンディング（リロード時は変化なし）
const pending = ref(isNeedHeaderData.value);

//pending.value = false;
//const { pending } = useLazyAsyncData("get_master", () => CardMaster.update());

const refTap = ref(null);
const tap = (e) => {
  //タップエフェクト（tapEffectメソッドはTapコンポーネントに記載）
  refTap?.value.tapEffect(e);
};

// ヘッダ情報取得が必要になるまで非同期で待ち、未取得の場合、取得
until(isNeedHeaderData).toBe(true).then(async ()=>{
  if (!data.value) {
    await reloadHeader();
  }
  pending.value = false;
})

// 未ログインでゲストページ外に遷移した場合、ログインへ
watch([isGuestPage, isLoggedIn, headerPending], async () => {
  if (!isGuestPage.value && !isLoggedIn.value && !headerPending.value) {
    console.log("redirect to login");
    location.href = '/login';
  }
});

onMounted(() => {
  if ($ua.isApp()) {
    new ClientFunctionsStrategy($ua).cf_app_canCallApi(() => {}, true);
  }

  document.body.addEventListener(
    "touchstart",
    (e) => { e.stopPropagation(); },
    { capture: false, once: false, passive: false }
  );

  document.body.addEventListener(
    "touchend",
    (e) => { e.stopPropagation(); },
    { capture: false, once: false, passive: false }
  );
});
</script>

<style lang="scss">
html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  text-size-adjust: 100%;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  height: -webkit-fill-available;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN",
    "メイリオ", Meiryo, sans-serif;

  /*  background-color: #000; */
  background-color: white;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  user-select: none;
  line-height: 1.3;
  font-size: 16px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.v-application {
  line-height: 1.3;
  font-size: 16px;
}
/* 背景
  todo サイズや背景の切り替えはまた別で行う
*/
.bg {
  width: 100%;
  max-width: 1920px;
  height: 100%;
}

/*.bg.normal:before {
  z-index: -1;
  position: fixed;
  top: 46px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000 url($snsPath + "/sp/img/2019/bg.jpg") no-repeat top/100%;
  content: '';
}*/
.bg.normal {
  background: #000;// url($snsPath + "/sp/img/2019/bg.jpg") no-repeat top/100%;
  background-position-y:46px;
  /*background-image: url($snsPath + "/sp/img/2023/bg.jpg"); //!important;
  background-size: contain;
  background-position-y: 46px; // ヘッダーの高さだけ下にずらす
  background-repeat: no-repeat;
  background-color: #303030;*/
}

.bg.normal.no_header {
  background-position-y: 0px; // ヘッダーなし分もどす
}

.bg.normal.not_login {
  background-position-y: 78px; // 未ログイン時のヘッダは高さが異なる
}
/**
 * vuetifyデフォルトのスクロール要素でスクロールされないようにする
 * @see https://github.com/vuetifyjs/vuetify/issues/1197
 */
html {
  @include rigid-box;
  width: 100%;

  body {
    @include rigid-box;
    width: 100% !important;

    #__nuxt {
      @include rigid-box;

      @include flexCenter;
      width: 100%;
      background-color: black;
      // background: url(~assets/jpbl/themes/common/pc/pc_bg.jpg);
      background: url("/lp/img/bg.png");
      background-position: center;
      background-size: cover;
      font-family: "Roboto",sans-serif;
      .v-application {
        @include rigid-box;
        @include app-width;

        .v-application--wrap {
          @include rigid-box;

          .v-main {
            @include rigid-box;

            // .v-main__wrap > div {
            //   padding-bottom: 56px
            // }
          }
        }
      }
    }
  }
}

/**
 * 各ページで、スクロールさせたい要素に .scrollable クラス属性を追加する
 */
.scrollable {
  height: 100% !important;
  min-height: initial !important;
  overflow-y: auto !important;
  scroll-behavior: smooth;
}

.scrollable_v-main {
  height: 100% !important;
  min-height: initial !important;
  overflow-y: auto !important;
  scroll-behavior: smooth;
}

.no_scroll {
  overflow-y: hidden !important;
}

.v-application {
  p {
    margin-bottom: auto;
  }
  .title {
    &.hudson {
      font-family: BLGHudson !important;
    }
  }
}

/** ダイアログが、v-application内から外れてv-overlay-container配下になる */
.v-overlay-container {
  /** ダイアログ内のフォントをv-applicationから転用 */
  font-family: "Roboto",sans-serif;
}

div.v-dialog .v-overlay__content {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  height: 90%;
  max-width: 100%;
  max-height: 90%;
  overflow: hidden;
  contain: none;
}
div.v-dialog .v-overlay__content > * {
  width: 100%;
}

.v-overlay {
  position: absolute;
}

.bottom_btn {
  @include app-width;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 60px;
  pointer-events: none;
  z-index: 110;
}
.bottom_btn_ios {
  @include app-width;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 136px;
  pointer-events: none;
}

.no_touch {
  touch-action: none;
}
</style>
