import { validateGeneralName } from "~/utils/validator/general_name";
import CreditCardValidator from "~/utils/validator/payment/credit_card";

export default class CreditCard {
  /**
   * クレカ番号。最大19桁
   * @type {string}
   */
  _number = "";

  get number() {
    return this._number;
  }

  set number(val) {
    this._number = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateNumber() {
    const result = CreditCardValidator.validateCardNumber(this.number);
    if (!result.isValid) throw result.toError();
  }

  /**
   * ハイフン付きにフォーマットされた番号を返す
   * @type {string}
   */
  get numberFormatted() {
    //return this.number;

    let formatted = "";
    for (let idx in this.number) {
      idx = Number(idx);

      formatted += this.number.charAt(idx);
      if ((idx % CreditCard.hyphenateAfter) === 3 && idx !== (this.number.length - 1)) {
        formatted += "-";
      }
    }
    return formatted;
  }

  /**
   * ハイフンを取り除いてから_numberに代入する
   */
  set numberFormatted(val) {
    this._number = val.trim().replaceAll("-", "");
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateNumberFormatted() {
    if (this.isCustIdPurchase) return;
    const result = CreditCardValidator.validateHyphenatedCardNumber(this.numberFormatted);
    if (!result.isValid) throw result.toError();
  }

  /**
   * 2桁の有効期限月（"01"、"02"など）
   * @type {string}
   */
  _monthStr = "";

  /**
   * @returns {string}
   */
  get monthStr() {
    return this._monthStr;
  }

  /**
   * @param {string}
   */
  set monthStr(val) {
    return this._monthStr = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateMonthStr() {
    if (this.isCustIdPurchase) return;
    const result = CreditCardValidator.validateExpiryMonth(this.monthStr);
    if (!result.isValid) throw result.toError();
  }

  /**
   * @deprecated Number型での用途がないので、monthStrを使うようにする
   * @returns {number}
   */
  get month() {
    return Number(this._monthStr);
  }

  /**
   * 有効期限年
   * @type {string}
   */
   _year = "";

   /**
    * @returns {string}
    */
   get year() {
     return this._year;
   }
 
  /**
   * @param {string}
   */
  set year(val) {
    return this._year = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateYear() {
    if (this.isCustIdPurchase) return;
    const result = CreditCardValidator.validateExpiryYear(this.year);
    if (!result.isValid) throw result.toError();
  }

  /**
   * セキュリティコード
   * @type {string}
   */
  _securityCode = "";

  /**
   * @returns {string}
   */
  get securityCode() {
    return this._securityCode;
  }

  /**
   * @param {string}
   */
  set securityCode(val) {
    return this._securityCode = val.trim();
  }

  /**
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
  validateSecurityCode() {
    if (this.isCustIdPurchase) return;
    const result = CreditCardValidator.validateSecurityCode(this.securityCode);
    if (!result.isValid) throw result.toError();
  }

  /**
   * トークン
   * @type {string}
   */
   _token = "";

   /**
   * @returns {string}
   */
   get token() {
     return this._token;
   }
 
   /**
   * @param {string}
   */
   set token(val) {
     return this._token = val.trim();
   } 

  /**
   * @param {boolean} isOptional
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
   validateToken(isOptional = false) {
    if (this.isCustIdPurchase) return;
    const result = CreditCardValidator.validateToken(this.token, isOptional);
    if (!result.isValid) throw result.toError();
  }

  /**
   * トークンキー
   * @type {string}
   */
   _tokenKey = "";

   /**
   * @returns {string}
   */
   get tokenKey() {
     return this._tokenKey;
   }
 
   /**
   * @param {string}
   */
   set tokenKey(val) {
     return this._tokenKey = val.trim();
   }     
   
  /**
   * @param {boolean} isOptional
   * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
   */
   validateTokenKey(isOptional = false) {
    if (this.isCustIdPurchase) return;
    const result = CreditCardValidator.validateTokenKey(this.tokenKey, isOptional);
    if (!result.isValid) throw result.toError();
  }

    /**
     * 顧客id支払い
     * @type {number}
     */
    _isCustIdPurchase = 0;

    /**
     * @returns {number}
     */
    get isCustIdPurchase() {
        return this._isCustIdPurchase;
    }

    /**
     * @param {number}
     */
    set isCustIdPurchase(val) {
        return this._isCustIdPurchase = val;
    }
    /**
      * @param {boolean} isOptional
      * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
      */
    validateIsCustIdPurchae(isOptional = false) {
    }
    /**
     * 顧客id支払い可能か
     * @type {number}
     */
    _custIdPurchasable = 0;

    /**
     * @returns {number}
     */
    get isCustIdPurchaseable() {
        return this._custIdPurchasable;
    }

    /**
     * @param {number}
     */
    set custIdPurchasable(val) {
        return this._custIdPurchasable = val;
    }

    /**
     * @param {boolean} isOptional
     * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
     */
    validateCustIDPurchasable(isOptional = false) {
    }
    /**
     * 顧客id保存するか
     * @type {number}
     */
    _isCustManage = 0;

    /**
     * @returns {number}
     */
    get isCustManage() {
        return this.isCustManage;
    }

    /**
     * @param {number}
     */
    set isCustManage(val) {
        return this.isCustManage = val;
    }

    /**
     * @param {boolean} isOptional
     * @throws {Error} バリデーションが通らなかった場合はエラーを投げる
     */
    validateIsCustManage(isOptional = false) {
    }

    /**
   * クレカ番号をハイフンで区切る桁数
   * @type {number}
   */
   static hyphenateAfter = 4;
}

export class CreditCardBuilder {
  constructor($config) {
    this.$config = $config;
  }

  build() {
    const cc = new CreditCard();

    // local/dev環境の場合にはデフォルト値をセット
    if (["local", "development"].includes(process.env.NODE_ENV)) {
    }

    return cc;
  }
}