<template>
  <a
    data-ajax="false"
    class="btn-select-album"
    @click="click()"
  >
    <AlbumImg :season="props.season" />
  </a>
</template>
<script>
/** アルバム
 * @module JpblAlbum
 */
export default defineNuxtComponent({
  name: "jpblAlbum",
});
</script>
<script setup>
import moment from 'moment';
import AlbumImg from "@/components/jpbl/images/album.vue";

const props = defineProps({
  season: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['click'])
const click = () => emits('click');
</script>

<style lang="scss" scoped>
</style>
