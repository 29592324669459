<template>
  <div id="menu-panel">
    <div
      class="panel-header hudson"
      style="font-size: 32px; padding-top: 0.5em"
    >
      menu
    </div>
    <div class="panel-body">
      <template v-for="(cols, i) in items" :key="i">
        <div class="cf">
          <div
            v-for="(n, idx) in cols"
            :id="`menu-btn-${n.name}`"
            class="v-col-4 menu-item"
            @click="n.on"
          >
            <img :src="n.src" />
            <span>{{ n.desc }}</span>
          </div>
        </div>
      </template>
      <div class="cf">
        <div class="v-col-12 menu-item" style="line-height: 1em">
          <div
            :id="`menu-btn-${policy.name}`"
            to="/user_policy/tokusho"
            class="btn image_menu_link"
            @click="policy.on()"
          >
            <span>{{ policy.desc }}</span>
          </div>
        </div>
      </div>
    </div>
    <button class="close" @click="close">
      <img :src="closeBtnImg" />
    </button>
  </div>
</template>

<script>
/** メニューパネルダイアログコンポーネント
 * @module JpblMenuPanelDialog
 * @vue-props {MenuPanelDialogCtrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblMenuPanelDialog",
});
</script>

<script setup>
import MenuPanelDialogCtrl from "./controller";
import { GetFolderCommand } from "~~/commands/user";
import InformationDialogCtrl from "../information/controller";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();
const config = useRuntimeConfig();
const infoImg = config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_info.png";
const profileImg =
  config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_profile.png";
const searchImg =
  config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_IDsearch.png";
const cardpackImg =
  config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_cardpack.png";
const cardHolderImg =
  config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_card_holder.png";
const cardAlbumImg = config.public.SNS_PATH + "sp/img/2018/icon_card_album.png";
const tradeImg = config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_trade.png";
const historyImg = config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_history.png";
const helpImg = config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_help.png";
const syncDataImg = config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_sync_data.png";
const nftLinkImg = config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_nft_link.png";
const serialLinkImg = config.public.SNS_PATH + "sp/img/layout/blg/icons/icon_serial.png";
const closeBtnImg = config.public.SNS_PATH + "sp/img/layout/blg/btn_close.png";
const props = defineProps({
  ctrl: {
    type: MenuPanelDialogCtrl,
    required: true,
  },
});

const close = () => dialogAdapter.close();

const { $router } = useNuxtApp();

const items = [
  [
    {
      name: "info",
      desc: "お知らせ",
      on: async () => {
        await close();
        dialogAdapter.open(new InformationDialogCtrl(true));
      },
      src: infoImg,
    },
    {
      name: "profile",
      desc: "プロフィール",
      on: () => {
        close();
        $router.push({ path: "/menu" });
      },
      src: profileImg,
    },
    {
      name: "friend",
      desc: "ID検索",
      on: () => {
        close();
        $router.push({ path: "/friend", query: { view: "search_uid" } });
      },
      src: searchImg,
    },
  ],
  [
    {
      name: "gacha",
      desc: "カードパック",
      on: () => {
        close();
        $router.push({ path: "/gacha" });
      },
      src: cardpackImg,
    },
    {
      name: "card-holder",
      desc: "カードホルダー",
      on: () => {
        close();
        $router.push({ path: "/folder" });
      },
      src: cardHolderImg,
    },
    {
      name: "album",
      desc: "アルバム",
      on: () => {
        new GetFolderCommand()
          .execute()
          .then((result) => {
            close();
            $router.push({
              path: "/folder",
              query: { target_year: result.target_year },
            });
          })
          .catch((err) => {});
      },
      src: cardAlbumImg,
    },
  ],
  [
    {
      name: "minimail",
      desc: "トレード履歴",
      on: () => {
        close();
        $router.push({ path: "/minimail" });
      },
      src: tradeImg,
    },
    {
      name: "trophy",
      desc: "履歴",
      on: () => {
        close();
        $router.push({ path: "/trophy" });
      },
      src: historyImg,
    },
    {
      name: "help",
      desc: "ヘルプ",
      on: () => {
        close();
        $router.push({ path: "/page/Howto" });
      },
      src: helpImg,
    },
  ],
  [
    {
      name: "nft-link",
      desc: "NFT連携",
      on: () => {
        close();
        $router.push({ path: "/nft_connect" });
      },
      src: nftLinkImg,
    },
    {
      name: "serial",
      desc: "シリアルページ",
      on: () => {
        close();
        $router.push({ path: "/serial" });
      },
      src: serialLinkImg,
    },
  ],
];

const policy = {
  name: "tokusho",
  desc: "利用規約・特定商取引法",
  on: () => {
    close();
    $router.push({ path: "/user_policy/tokusho" });
  },
  src: null,
};
</script>

<style lang="scss" scoped>
.close {
  padding: 3px;
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 44px;
  z-index: 100; // 移植前には無かったスタイルだが、ボタンが隠れてしまうので追加
}

.close img {
  width: 100%;
}

// #menu-panel.overlay {
//   transform: none;
//   background-color: rgb(0 0 0 / 60%);
//   height: 100%;
//   left: 0;
//   position: fixed;
//   display: block;
//   top: 0;
//   width: 100%;
//   z-index: 1001;
// }

.col-4 {
  display: inline-block;
  float: left;
  width: 33.3333% !important;
}

#menu-panel {
  background-color: #000;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

#menu-panel .panel-header {
  color: #fff;
  font-size: 22px;
  height: 44px;
  margin-bottom: 25px;
  padding-top: 11px;
  text-align: center;
}

#menu-panel .panel {
  // margin-top: -500px;
}

#menu-panel .menu-item {
  color: #fff;
  margin-bottom: 0.5em;
  position: relative;
  text-align: center;
}

#menu-panel .menu-item a {
  color: rgb(195 193 193);
  font-weight: normal;
}

#menu-panel .menu-item img {
  margin: 0 auto 3px;
  // width: 46px;
}

#menu-panel .menu-item.is-badge::after {
  background-color: #d0021b;
  border-radius: 5px;
  content: "";
  height: 10px;
  position: absolute;
  right: 28%;
  top: 5%;
  width: 10px;
}

#menu-panel .menu-item span {
  display: block;
  font-size: 10px;
}

.cf {
  display: flex;
}

#menu-btn-nft-link {
  img {
    width: 64px;
  }
  span {
    padding-top: 10px;
  }
}
#menu-btn-serial {
  span {
    padding-top: 10px;
  }
}
</style>
