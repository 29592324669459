<template>
  <v-card class="crystal-purchase-dialog">
    <v-card-title
      class="text-subtitle-1 font-weight-bold bg-grey-darken-1 text-white d-flex justify-center mb-4"
    >
      B.クリスタル購入
    </v-card-title>

    <v-card-text
      class="crystal-purchase-dialog__for-minors text-black text-center"
    >
      未成年の方はお父さんお母さんに相談してからか、一緒の時に買うようにしてください。
    </v-card-text>

    <v-card-text>
      <div class="crystal-purchase-dialog__img-container">
        <JpblIconCrystalImg
          class="crystal-purchase-dialog__img-container__img"
          :file-name="props.ctrl.itemImgFilename"
        />
        <div class="crystal-purchase-dialog__img-container__texts">
          <span class="crystal-purchase-dialog__img-container__texts__count">{{
            props.ctrl.countText
          }}</span>
          <span class="crystal-purchase-dialog__img-container__texts__price">{{
            props.ctrl.priceText
          }}</span>
        </div>
      </div>
    </v-card-text>

    <v-card-text class="text-black font-weight-bold text-center">
      B.クリスタルを購入しますか？
    </v-card-text>
    <div v-if="props.ctrl.item.remain_count" class="remain_count">
      あと{{ props.ctrl.item.remain_count }}回
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn min-width="100px" variant="outlined" @click="cancel"
        >キャンセル</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        variant="flat"
        min-width="100px"
        color="primary font-weight-bold"
        @click="buy"
        >購入する</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-card-text class="crystal-purchase-dialog__notice text-black text-center">
      ※OSをまたいだ機種変更の場合、<br />
      B.クリスタルのデータは紛失します。<br />
      また、有償クリスタルはアプリ版、<br />
      ブラウザ版にて統合することはできません。<br />
    </v-card-text>
  </v-card>
</template>
<script>
/** クリスタル購入ダイアログコンポーネント
 * @module JpblCrystalPurchaseDialog
 * @vue-props {CrystalPurchaseDialogCtrl} ctrl
 */
export default defineNuxtComponent({
  name: "jpblCrystalPurchaseDialog",
});
</script>

<script setup>
import JpblIconCrystalImg from "@/components/jpbl/images/icon_crystal.vue";
import CrystalPurchaseDialogCtrl from "./controller";
import SelectPaymentMethodDialogCtrl from "@/components/payment/select_payment_method/controller";
import ClientFunctionsStrategy from "~/utils/modules/sp/client_functions_strategy";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();
const { $ua } = useNuxtApp();

const props = defineProps({
  ctrl: {
    type: CrystalPurchaseDialogCtrl,
    required: true,
  },
});

const buy = () => {
  if ($ua.isApp()) {
    new ClientFunctionsStrategy($ua).cf_store_payment(
      () => {},
      props.ctrl.seihin_id,
    );
  } else
    dialogAdapter.open(
      new SelectPaymentMethodDialogCtrl(
        props.ctrl.item,
        props.ctrl.is_cust_id_purchasable,
        props.ctrl.last_credit_card_info,
      ),
    );
};

const cancel = () => dialogAdapter.close();
</script>

<style lang="scss" scoped>
.crystal-purchase-dialog {
  .v-card-text {
    line-height: 1.375rem !important;
  }

  &__for-minors {
    font-size: 11px !important;
  }

  &__img-container {
    width: 100%;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 0;

    &__texts {
      color: white;
      font-size: 15px;
      font-weight: bold;
      span {
        padding: 0 5px 0 5px;
      }
    }
  }

  &__notice {
    font-size: 11px !important;
  }
  .remain_count {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}
</style>
