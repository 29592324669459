import { ValidationCode, ValidationResult } from ".";

/**
 * カナ氏名をバリデーションする
 * @param {string} name
 * @returns {ValidationResult}
 */
export function validateNameKana(name) {
  /** 空でないこと */
  if (!name)
    return ValidationResult.createInvalid(
      "カナ氏名は空でない必要があります",
      ValidationCode.EmptyNotAllowed
    );

  /** 全角カタカナまたは空白のみであること */
  if (/[^ァ-ヴー 　]/.test(name))
    return ValidationResult.createInvalid(
      "カナ氏名は全角カタカナまたは空白のみである必要があります",
      ValidationCode.IllegalChars
    );

  return ValidationResult.createValid();
}
