<template>
  <v-app-bar
    v-if="!is_hide_header"
    elevation="0"
    :height="header_height"
    :absolute="true"
    :style="{'z-index': 100}"
    app
  >
    <div v-if="isLoggedIn" id="contents_header">
      <!-- ヘッダー背景 -->
      <img
        class="bg_header"
        :src="`${config.public.SNS_PATH}sp/img/2019/header.png?t=20180926`"
      />

      <!-- プレイヤーアイコン -->
      <img v-if="hasProfilePhoto" :src="thumbPath" class="player_icon" />
      <img
        v-else
        :src="`${config.public.SNS_PATH}img/layout/default_no_profile_photo.png`"
        class="player_icon"
      />

      <!-- ランキング変動文言 -->

      <!-- ランク画像 -->
      <nuxt-link
        v-if="userClass"
        data-ajax="false"
        :to="{ path: '/mission/class' }"
      >
        <JpblIconClassImg
          :class-name="userClass"
          html-class-attribute="icon_class"
        />
      </nuxt-link>
      <img
        v-else
        class="icon_class"
        :src="`${config.public.SNS_PATH}sp/img/2018/icon_class_non.png`"
      />

      <!-- ランクのアップダウン -->

      <!-- 各値 -->
      <span class="header_val_name">{{ nickname }}</span>
      <span class="header_val_ranking"
        >{{ rank }}<span class="small">位</span></span
      >
      <span class="header_val_diamond">{{ crystalDisplay }}</span>
      <span class="header_val_point">{{ pointDisplay }}</span>

      <!-- クリスタル購入ボタン -->
      <nuxt-link data-ajax="false" :to="{ path: '/user/crystal_purchase' }">
        <img
          class="btn_purchase"
          :src="`${config.public.SNS_PATH}sp/img/layout/1PIX.gif`"
        />
      </nuxt-link>

      <!-- ランキングボタン 順位表示の所 -->
      <nuxt-link data-ajax="false" :to="{ path: '/mission/class' }">
        <img
          class="btn_ranking_1"
          :src="`${config.public.SNS_PATH}sp/img/layout/1PIX.gif`"
        />
      </nuxt-link>

      <!-- プロフィールボタン 名前表示の所 -->
      <nuxt-link data-ajax="false" :to="{ path: '/menu' }">
        <img
          class="btn_profile"
          :src="`${config.public.SNS_PATH}sp/img/layout/1PIX.gif`"
        />
      </nuxt-link>
    </div>
    <div v-else id="contents_header" class="not_login">
      <div class="p_headerNotLogin">
        <div class="p_headerLoginTitle">
          <span>B.LEAGUE CARD</span>で<br>
          自分だけのコレクションを作ろう！
        </div>
        <div class="btn_area">
          <div class="p_headerNotLoginBtn app">
            <a :href="store_url"><span>アプリ版で開く</span></a>
          </div>
          <div class="p_headerNotLoginBtn login">
            <nuxt-link data-ajax="false" :to="{ path: '/login' }">
              <span>ブラウザ版で開く</span>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
/** ヘッダーコンポーネント
 * @module Header
 */
export default defineNuxtComponent({
  name: "jpblHeader",
});
</script>

<script setup>
import { SNS } from "/utils/modules/sns-module";
import JpblIconClassImg from "@/components/jpbl/images/icon_class.vue";
import { useHeaderData } from "~/composables/useHeaderData";
const config = useRuntimeConfig();

const { isLoggedIn, data } = useHeaderData();
const header_height = computed(() => isLoggedIn.value ? "46" : "78");
const store_url = SNS.isAndroid() ? "https://play.google.com/store/apps/details?id=jp.bleague.ticket" : "https://apps.apple.com/jp/app/brigusumahochiketto/id1129793740";

const nickname = computed(() => data.value?.user?.nickname ?? "--");
const userClass = computed(() => data.value?.user_class ?? "--");
const crystalDisplay = computed(() => data.value?.crystal ? String(Number(data.value.crystal.free) + Number(data.value.crystal.paid)) : "--");
const pointDisplay = computed(() => data.value?.user?.point ?? "--");
const rank = computed(() => data.value?.monthly_rank ?? "--");
const hasProfilePhoto = computed(() => data.value?.user?.thumb_path && !(/default_no_profile_photo/.test(data.value.user.thumb_path)));
const thumbPath = computed(() => data.value?.user?.thumb_path ?? null);

const is_hide_header = ref(false);
</script>

<style lang="scss" scoped>
#contents_header .bg_header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
}

#contents_header .msg_ranking {
  font-size: 12px;
  font-weight: bold;
  color: yellow;
  z-index: 30;
  position: absolute;
  top: 3px;
  left: 140.5px;
}

#contents_header .player_icon {
  position: absolute;
  top: 7px;
  left: 11px;
  width: 32px;
  height: 32px;
  z-index: 14;
  border-radius: 50%;
}

#contents_header .icon_class {
  position: absolute;
  top: 2px;
  left: 36px;
  width: 41px;
  height: 41px;
  z-index: 15;
}

#contents_header a .btn_purchase {
  position: absolute;
  z-index: 20;
  top: 14px;
  right: 3px;
  width: 98px;
  height: 29px;
}

#contents_header a .btn_ranking_1 {
  position: absolute;
  z-index: 20;
  top: 4px;
  left: 38px;
  width: 130px;
  height: 40px;
}

#contents_header a .btn_profile {
  position: absolute;
  z-index: 20;
  top: 4px;
  left: 4px;
  width: 34px;
  height: 40px;
}

#contents_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 46px;
  overflow: visible;
  z-index: 102;
  -webkit-transition: all 0.25s linear;
}

.header_val_name {
  z-index: 20;
  position: absolute;
  top: 8px;
  right: 208px;
  font-size: 10px;
  line-height: 1;
  text-align: right;
  color: rgb(190 190 190);
  white-space: nowrap;
}

.header_val_ranking {
  z-index: 20;
  position: absolute;
  top: 21px;
  right: 225px;
  font-size: 14px;
  text-align: right;
  color: #ebebeb;
}

.header_val_ranking .small {
  font-size: 70%;
}

.header_val_ranking_updown_icon {
  z-index: 20;
  position: absolute;
  top: 21px;
  right: 209px;
  width: 14px;
  height: 16px;
}

.header_val_diamond {
  z-index: 20;
  position: absolute;
  top: 21px;
  right: 28px;
  font-size: 14px;
  text-align: right;
  color: #ebebeb;
}

.header_val_point {
  z-index: 20;
  color: #ebebeb;
  position: absolute;
  top: 21px;
  right: 110px;
  text-align: right;
  font-size: 14px;
}
#contents_header.not_login {
  height: 78px;
  .p_headerNotLogin {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 3px 12px;
    background-color: #3a3a3a;
    color: #ffffff;
    .p_headerLoginTitle {
      font-size: 12px;
      line-height: 24px;
      span {
        padding: 3px;
        font-size: 18px;
      }
    }
    .btn_area {
      display: flex;
      flex-flow: column;
      padding: 0px 0px;
      .p_headerNotLoginBtn {
        width: 130px;
        height: 28px;
        margin: 5px 0px;
        border: solid 2px #000;
        border-radius: 20px;
        a {
          display: block;
          text-align: center;
          line-height: 24px;
        }
        span {
          font-size: 12px;
          font-weight: bold;
          color: #000;
        }
      }
      .p_headerNotLoginBtn.app {
        background: #ffbbbb;
      }
      .p_headerNotLoginBtn.login {
        background: #d0eeff;
      }
    }
  }
}
</style>
