import { PaymentMethod } from "~/models/payment";
import { ValidationCode, ValidationResult } from "..";

/**
 * 決済手段をバリデーションする。
 * @param {string} payMethod
 * @returns {ValidationResult}
 */
export function validatePayMethod(payMethod) {
  const allowedVals = Object.getOwnPropertyNames(PaymentMethod).map(
    (key) => PaymentMethod[key]
  );
  if (!allowedVals.includes(payMethod))
    return ValidationResult.createInvalid(
      `${allowedVals}以外の値は許されません`,
      ValidationCode.NotAllowed
    );

  return ValidationResult.createValid();
}
