<template>
  <div>
    <div id="newLoginBG" class="new_login_bonus_box_bg"></div>
    <div id="newLogin" class="new_login_bonus_box">
      <div class="new_login_bonus_contents">
        <div class="banner_area">
          <img :src="campaign.path_01" class="banner" />
          <img :src="holding_period_img_url" class="banner_frame" />
          <p class="schedule_str">開催期間</p>
          <p class="schedule_num">{{ start_at }}~{{ end_at }}</p>
        </div>
        <div class="items">
          <div class="items_content">
            <ul class="bonus_list">
              <li
                v-for="(detail, detail_idx) in login_board_details"
                :key="detail_idx"
                :class="detail.bonus_stamp_class"
              >
                <!-- 当日はtodayをセット -->
                <div class="filter">
                  <img
                      v-if="detail.imgframesrc"
                      :id="detail.imgid"
                      :src="detail.imgframesrc"
                      :class="detail.imgframeclass"
                  />
                  <img
                    :id="detail.imgid"
                    :src="detail.imgsrc"
                    :class="detail.imgclass"
                  />
                  <div class="count" v-if="detail.if_show_bonus_num_img">
                    <img
                      v-for="(
                        bonus_num, bonus_num_idx
                      ) in detail.arr_bonus_nums"
                      :key="bonus_num_idx"
                      :src="bonus_num.img_url"
                      :class="detail.num_image_class"
                    />
                  </div>
                  <img :src="detail.new_login_bonus_img_url" class="days" />
                </div>
                <div
                  v-if="detail_idx == login_board_details.length"
                  class="last_reward"
                >
                  {{ detail.last_day_reward }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="closeBtn">
        <a class="ui-link" @click="close">閉じる</a>
      </p>
    </div>

    <!-- 獲得確認時のポップアップ -->
    <div id="newLoginGetBG" class="new_login_bonus_get_box_bg"></div>
    <div id="newLoginGet" class="new_login_bonus_get_box">
      <h1>ログインボーナス</h1>
      <p class="day_count">ログイン{{ campaign.login_count }}日目！</p>
      <p class="caption">以下のアイテムを受け取りました</p>
      <div v-if="campaign.target_img === 'card'" class="get_item_discription">
        <p class="item_name">{{ campaign.target_text }}</p>
        <img :id="`today_reward_card_${campaign.id}`" src="" class="card" />
      </div>
      <div v-else class="get_item_discription">
        <p class="item_name">{{ campaign.target_text }} ×</p>
        <img :src="campaign.target_img" :class="campaign.target_class" />
        <p class="item_name">{{ amount }}{{campaign.target_unit_name}}</p>
      </div>
      <p class="closeBtn">
        <a
          data-ajax="false"
          href="javascript:void(0);"
          class="ui-link"
          @click="close_get_new_login_bonus"
          >× 閉じる</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  name: "jpblNewLoginBonus",
});
</script>

<script setup>
import moment from "moment";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

const config = useRuntimeConfig();

const props = defineProps({
  ctrl: {
    type: Object,
    required: true,
  },
});

const holding_period_img_url =
  config.public.SNS_PATH + "/sp/img/new_login_bonus/holding_period.png";
const campaign = props.ctrl.login_board_campaign;
campaign.target_unit_name = "";
// 当日ボーナス
if (campaign.today_reward.target_kind == 1) {
  // カード
  campaign.target_img = "card";
  campaign.target_text = campaign.today_reward.card_title;
} else if (campaign.today_reward.target_kind == 2) {
  // コイン
  campaign.target_img = config.public.SNS_PATH + '/sp/img/new_login_bonus/icon_coin.png';
  campaign.target_class = "item";
  campaign.target_text = "コイン";
  campaign.target_unit_name = "c";
} else if (campaign.today_reward.target_kind == 3) {
  // バルーン
  campaign.target_img = config.public.SNS_PATH + '/sp/img/new_login_bonus/icon_crystal.png';
  campaign.target_class = "item";
  campaign.target_text = "クリスタル";
  campaign.target_unit_name = "個";
} else if (campaign.today_reward.target_kind == 4 || campaign.today_reward.target_kind == 5) {
  let login_board_detail = campaign.login_board_details[campaign.login_count];
  campaign.target_img = config.public.SNS_PATH + '/sp/img/layout/blg/' + login_board_detail.present_item.use_item.icon_path;
  campaign.target_text = login_board_detail.present_item.use_item.name;
  campaign.target_class = "use_item";

} else {
  campaign.target_img = "";
  campaign.target_text = "";
}

// 翌日ボーナス対象の個数
const amount = campaign.today_reward.target_count;
const start_at = moment(campaign.start_at).format("M/D");
const end_at = moment(campaign.end_at).format("M/D");
const new_login_bonus_img_url =
  config.public.SNS_PATH + "/sp/img/new_login_bonus/" + campaign.target_img + ".png";
const login_board_details = Object.entries(campaign.login_board_details).map(
  ([key, detail], idx, details) => {
    const isLast = Number(key) === details.length;

    // 各ボーナススタンプのクラス
    let bonus_stamp_class = "notyet"; // 未取得

    if (detail.day_number == campaign.login_count) {
      bonus_stamp_class = "today"; // 当日
    } else if (detail.day_number < campaign.login_count) {
      bonus_stamp_class = "get"; // 取得済み
    }

    if (isLast) {
      bonus_stamp_class += " last_day";
      if (detail.present_item.target_kind == 1) {
        bonus_stamp_class += " is_card";
      }
    }

    // ログイン報酬対象
    let imgid = "";
    let imgsrc = config.public.SNS_PATH + "/sp/img/new_login_bonus/";
    let imgclass = "";
    let imgframesrc = '';
    let imgframeclass = '';
    if (detail.present_item.target_kind == 1) {
      // カード
      imgid = "reward_card_" + campaign.id;
      imgsrc = "";
      imgclass = "card";
    } else if (detail.present_item.target_kind == 2) {
      // コイン
      imgsrc += "coin.png";
      imgclass += "item";
    } else if (detail.present_item.target_kind == 3 && bonus_stamp_class.indexOf("last_day")) {
      // 最終日クリスタル
      imgsrc += "crystal_day7_resize.png";
      imgclass += "item";
    } else if (detail.present_item.target_kind == 3) {
      //クリスタル
      imgsrc += "crystal.png";
      imgclass += "item";
    } else if (detail.present_item.target_kind == 4 || detail.present_item.target_kind == 5) {
      //チケット 消費アイテム
      imgclass = "item";
      imgframesrc = imgsrc + "frame_normal.png";
      if (bonus_stamp_class.indexOf("last_day")) {
        imgframesrc = imgsrc + "frame_gold.png";
        imgframeclass = "item";
        imgclass = "item_icon";
      }
      imgsrc = config.public.SNS_PATH + "/sp/img/layout/blg/" + detail.present_item.use_item.icon_path;

    }
    // ログイン報酬対象の個数
    const amount = detail.present_item.target_count;

    const arr_bonus_nums = String(amount).split("");
    // 最終日用の切り分け
    let number_img_pass = null;
    let num_image_class = null;

    if (bonus_stamp_class.includes("last_day")) {
      number_img_pass = "/sp/img/new_login_bonus/g_number/";
      num_image_class = "count_num gold";
    } else {
      number_img_pass = "/sp/img/new_login_bonus/number/";
      num_image_class = "count_num";
    }

    let last_day_reward = "";
    if (detail.present_item.target_kind == 1) {
      // カード
      if (campaign && campaign.last_day_reward) {
        last_day_reward = campaign.last_day_reward.card_title;
      }
    }

    return {
      ...detail,
      bonus_stamp_class,
      imgid,
      imgsrc,
      imgclass,
      imgframesrc,
      imgframeclass,
      amount,
      arr_bonus_nums,
      num_image_class,
      last_day_reward,
      if_show_bonus_num_img: !(isLast && amount <= 1),
      arr_bonus_nums: arr_bonus_nums.map((bonus_num) => ({
        ...bonus_num,
        img_url: config.public.SNS_PATH + number_img_pass + bonus_num + ".png",
      })),
      new_login_bonus_img_url:
        config.public.SNS_PATH +
        "/sp/img/new_login_bonus/days/" +
        detail.day_number +
        ".png",
    };
  }
);

function close() {
  dialogAdapter.close();
}

// 新ログインボーナス獲得ポップアップを開く
function open_get_new_login_bonus() {
  $("#newLoginGetBG").show();
  $("#newLoginGet").fadeIn("fast");
}

// 新ログインボーナス獲得ポップアップを閉じる
function close_get_new_login_bonus() {
  $("#newLogin").fadeOut("fast");
  setTimeout(function () {
    $("#newLogin .after_block").css("display", "flex");
    $("#newLogin .bonus_list .today").removeClass("today").addClass("get");
    //var scrollHeight = (window.innerHeight - ((window.innerWidth / 375) * 460))
    //$('#newLogin .bonus_list').css('height',scrollHeight + 'px');
    $("#newLogin h1").hide();
    $("#newLoginGetBG").hide();
    $("#newLoginGet").fadeOut("fast");
    $("#newLogin").fadeIn("fast");
    $("#newLogin .closeBtn").show();
  }, 300);
}

onMounted(() => {
  $("#newLoginBG").show();
  $(".ui-page").css({ position: "fixed" });
  //var scrollHeight = (window.innerHeight - ((window.innerWidth / 375) * 355))
  //$('#newLogin_' + id + ' .bonus_list').css('height',scrollHeight + 'px');
  setTimeout(function () {
    //高さ取得のために一瞬表示
    var obj = $("#newLogin");
    obj.css("display", "block");
    var blockHeight = $("#newLogin .bonus_list li").eq(1).height();
    obj.css("display", "none");

    //$('#newLogin_' + id + ' .bonus_list .last_day').height(blockHeight * 1.55);
    $("#newLogin .closeBtn").hide();

    obj.fadeIn("fast");
    setTimeout(function () {
      open_get_new_login_bonus();
    }, 3000);
  }, 500);
});
</script>
<style lang="scss" scoped>
/* 新ログインボーナス ****************************************************************/

.new_login_bonus_box_bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: 1000;
}

.new_login_bonus_box {
  display: none;
  position: fixed;
  top: 50%; left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  background: transparent;
  color: #000;
  z-index: 1001;
  .header_img {
    width: 100%;
  }
  h1 {
    padding: 2.5% 0;
    background: #c3c1c1;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
  }
  .banner_area {
    width: 100%;
    position: relative;
    p {
      color: white;/*文字は白に*/
      font-weight: bold;
      // font-size: 1.8em;
    }
    .banner {
      position: absolute;
      width: 84.2%;
      height: 62%;
      top: 4%;
      left: 8%;
    }
    .banner_frame {
      width: 88%;
      margin: auto;
    }
    .schedule_num {
      display: inline-block;
      font-size: 1.4em;
      margin: -4.4px;
      position: absolute;
      width: 40%;
      top: 76%;
      left: 52%;
      font-family: BLGHudson;
    }
    .schedule_str {
      display: inline-block;
      font-size: 1.3em;
      position: absolute;
      top: 74.3%;
      left: 25%;
    }
  }

  .after_block {
    display: none; /* 後半flex */
    margin-top: 3%;
    width: 100%;
    align-items: center;
    .remain {
      position: relative;
      flex-basis: 55%;
      color: #FFF;
      font-weight: bold;
      text-shadow:    1px  1px 0px #000, -1px  1px 0px #000,
      1px -1px 0px #000, -1px -1px 0px #000;
      .bg_image {
        display: block;
        margin: 0 auto;
        width: 90%;
      }
      .item_image {
        position: absolute;
        top: 5%; left: 25%;
        width: 50%;
      }
      .title {
        position: absolute;
        top: 3%; left: 9%;
      }
      .value {
        position: absolute;
        bottom: -2%; right: 9%;
      }
      .small { font-size: 3.5vw; }
      .large { font-size: 4.5vw; }
      .day { font-size: 6.5vw; }
      .orange { color: #FCC800; }
    }
    .next_day {
      position: relative;
      flex-basis: 45%;
      color: #000;
    }
    /* 明日はこれ部分 */
    .next_day .bg_image {
      display: block;
      margin-left: -5%;
      width: 95%;
    }
    .next_day .item_image {
      position: absolute;
      top: 21%; left: 28%;
      width: 30%;
    }
    .next_day .title {
      position: absolute;
      top: -15%; left: 5%;
      width: 76%;
    }
    .next_day .value {
      position: absolute;
      bottom: 7%; left: 0;
      width: 85%;
      text-align: center;
    }
  }

  /* 報酬一覧 */
  .items {
    margin: 0 auto;
    width: 90%;
    .title {
      margin-left: -2.1%;
      width: 105.6%;
      max-width: 105.6%;
    }
    .footer {
      padding: 3% 0;
      border-radius: 0 0 6px 6px;
      background: #FCC800;
    }
  }
  .items_content {
    background: url($snsPath + "sp/img/new_login_bonus/back_full.png") no-repeat;
    background-size: 100% 105%;
    background-position: center;

    .period {
      padding: 1.5% 0;
      width: 100%;
      background: #FFF;
      font-size: 4.5vw;
      font-weight: 1000;
      text-align: center;
      color: #000;
    }
    .bonus_list {
      /*overflow-y: scroll;*/
      /*margin: 0 auto;*/
      padding: 10% 3% 10%;
      /*width: 95%;*/
      text-align: center;

      &::-webkit-scrollbar{ /* スクロールバー本体 */
        width: 5px;
        background: #ededed;
      }
      &::-webkit-scrollbar-thumb{ /* スクロールバーの動く部分 */
        background: #888888;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-track-piece:start{ /* スクロールバーの動く部分の前 */
        background: #ededed;
      }
      &::-webkit-scrollbar-track-piece:end{ /* スクロールバーの動く部分の後ろ */
        background: #ededed;
      }
      &::-webkit-scrollbar-corner{ /* スクロールバー角 */
        background: transparent;
      }

      &:after {
        display: block;
        clear: both;
        content: '';
      }

      li {
        display: inline-block;
        position: relative;
        top: 20px;
        margin: 0% -2.5% 3% 0;
        width: 24%;
        /*vertical-align: middle;*/
      }
      .count {
        position: absolute;
        bottom: 14%; 
        right: 16%;
        width: 30%; 
        height: 23%;
        text-align: right;
      }
      .count_num {
        display: inline-block;
        height: 100%;
        letter-spacing: -2px;
        margin: -1px;
      }
      .notyet:after {
        position: absolute;
        display: block;
        top: 0; 
        left: 0;
        width: 100%; 
        height: 100%;
        background-size: contain;
        content: '';
      }
      
      .get:after {
        z-index: 2;
        position: absolute;
        display: block;
        top: 12%; 
        left: 12%;
        width: 76%; 
        height: 100%;
        background: url($snsPath + "sp/img/new_login_bonus/GET.png") no-repeat;
        background-size: contain;
        content: '';
      }
      .filter {
        position:relative;
      }
      .filter .item_icon{
        position:absolute;
        top:0px;
      }
      .get .filter {
        opacity: 0.7;
      }
      .days {
        z-index: 2;
        position: absolute;
        top: -16%; left: 52%;
        height: 28%;
        transform: translateX(-50%);
      }
      .today:after {
        z-index: 1;
        position: absolute;
        display: block;
        top: -10%; left: -10%;
        width: 120%; height: 120%;
        background: url($snsPath + "sp/img/new_login_bonus/TodaysBonus_Circle.png") no-repeat;
        background-size: contain;
        content: '';
        -webkit-animation: today_circle 1.0s linear infinite;
      }

      /* 最終日専用 */
      .last_day {
        width: 40%;
        height: 6.8em;
        right: 0.6em;
      }

      .last_day .item_icon {
        top: 7%;
        left: 7%;
        width: 85%;
      }

      .last_day .card {
        position: absolute;
        top: 12%; left: 50%;
        width: 40%;
        transform: translateX(-50%);
      }
      .last_day .days {
        z-index: 2;
        /*position: absolute;*/
        /*top: -15%;*/
        left: 50%;
        /*height: 23%;*/
        transform: translate(-50%,50%);
      }
      .last_day .count {
        /*position: absolute;*/
        bottom: 8%; right: 24%;
        /* width: 30%; height: 20%;*/
        /*text-align: right;*/
      }
      .last_day.get:after {
        z-index: 1;
        position: absolute;
        display: block;
        top: 13%; left: 39%;
        width: 52%; height: 100%;
        background: url($snsPath + "sp/img/new_login_bonus/GET.png") no-repeat;
        background-size: contain;
        transform: translateX(-50%);
        content: '';
      }
      .last_day.get.is_card:after {
        background: url($snsPath + "sp/img/new_login_bonus/GET.png") no-repeat; /*done_last.png*/
        background-size: contain;
      }
      .last_day.today:after {
        left: -5%;
        top: -22%;
      }
      .last_day .last_reward {
        /*position: absolute;
        padding: 3% 2% 0%;
        bottom: 5%; left: 4%;
        width: 93%;
        background: url($snsPath + "sp/img/new_login_bonus/reward_name_bg.png") no-repeat;
        background-size: contain;
        font-size: 3vw;
        color: #000;
        font-weight: bold;
        text-align: center;*/
      }
    }
  }
  .closeBtn {
    a {
      position: relative;
      display: block;
      width: 150px;
      padding: 10px;
      margin: 10px auto;
      border-radius: 30px;
      background: #ffffff;
      text-align: center;
      color: #000000;
    }
  }
}

@-webkit-keyframes today_circle {
  0% { opacity: 0; -webkit-transform: scale(1.0); }
  50% { opacity: 1; -webkit-transform: scale(1.3);  }
  100% { opacity: 0; -webkit-transform: scale(1.6);  }
}


/* 獲得確認ポップアップ ****************************************************************/
.new_login_bonus_get_box_bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: 1005;
}
.new_login_bonus_get_box {
  display: none;
  position: fixed;
  top: 50%; left: 5%;
  width: 90%;
  -webkit-transform: translateY(-50%);
  background: #D8D8D8;
  color: #000;
  z-index: 1006;
  text-align: center;
  border-radius: 10px;

  h1 {
    padding: 10px 0;
    background: #828282;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    border-radius: 10px 10px 0 0;
  }
  .day_count {
    margin: 7px;
    font-size: 18px;
    font-weight: bold;
  }
  .caption {
    margin-bottom: 10px;
    font-size: 12px;
  }
  .item {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
  }
  .use_item {
    width: 2em;
    height: 2em;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
  }
  .card {
    display: inline-block;
    margin-bottom: 10px;
    width: 40%;
  }
  .item_name {
    /*font-size: 16px;*/
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
  }
  .get_item_discription {
    background-color: #333;
    padding: 5% 0%;
    margin: 0% 6%;
  }
  .footer {
    padding: 10px 0;
    border-radius: 0 0 6px 6px;
    background: #D8D8D8;
  }
  .closeBtn a {
    display: block;
    position: relative;
    margin: 5% auto;
    padding: 10px;
    width: 150px;
    border: 1px solid #000;
    border-radius: 30px;
    text-align: center;
    color: #000;
  }
}
</style>
