<template>
  <!-- アルバム選択ポップアップ -->
  <div class="modal_dialog">
    <h1>アルバム選択</h1>
    <ul class="choose_album">
      <li v-for="season in seasonList" :key="season">
        <album :season="season" @click="click(season)" />
      </li>
    </ul>
    <p class="closeBtn">
      <a @click="close">閉じる</a>
    </p>
  </div>
</template>
<script>
/** アルバム選択ダイアログ
 * @module JpblChooseAlbumDialog
 */
export default defineNuxtComponent({
  name: "jpblChooseAlbumDialog",
});
</script>

<script setup>
import ChooseAlbumDialogCtrl from "./controller";
import Album from "@/components/folder/album.vue";
import { useDialogAdapter } from "~/composables/useDialogAdapter";
const { dialogAdapter } = useDialogAdapter();

const props = defineProps({
  ctrl: {
    type: ChooseAlbumDialogCtrl,
    required: true,
  },
});

const seasonList = props.ctrl.seasonList;

const close = () => {
  dialogAdapter.close();
  return false;
};

const click = (season) => {
  props.ctrl.onChooseAlbum(season);
};
</script>

<style lang="scss" scoped>
.modal_dialog {
  width: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1001;
  font-size: 14px;
  text-align: center;
  background-color: #d8d8d8;
  border: 0;
  border-radius: 12px;
  color: #333;
  overflow: hidden
}

.modal_dialog h1 {
  background-color: #828282;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  font-size: 16px
}

.modal_dialog p {
  margin: 10px 0
}

.modal_dialog_content {
  width: 90%;
  max-height: 100px;
  overflow-y: scroll;
  margin: 10px auto;
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  font-size: 12px
}

.modal_dialog_content li {
  margin: 5px 0
}

.modal_dialog_content li .icon_point {
  display: inline-block;
  width: 15px;
  vertical-align: sub;
  margin: 0 5px
}

.modal_dialog .closeBtn a {
  position: relative;
  display: block;
  width: 150px;
  padding: 10px;
  margin: 20px auto;
  border: 1px solid #000;
  border-radius: 30px;
  text-align: center;
  color: #000
}

.modal_dialog .closeBtn a::before {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  transform: translateY(-50%);
  content: "×";
  font-size: 30px;
  height: 35px;
  line-height: 30px;
  font-weight: bold
}

.choose_album {
  margin: 20px auto;
  width: 100%;
  padding-left: 0
}

.choose_album li {
  margin-bottom: 15px;
  text-align: center
}

:deep(.choose_album li) {
  a {
    margin: 0 auto;
    width: 150px;

    img {
      width: 100%
    }
  }
}
</style>
