import { ValidationCode, ValidationResult } from ".";

/**
 * 漢字氏名をバリデーションする
 * @param {string} name
 * @returns {ValidationResult}
 */
export function validateNameKanji(name) {
  /** 空でないこと */
  if (!name)
    return ValidationResult.createInvalid(
      "漢字氏名は空でない必要があります",
      ValidationCode.EmptyNotAllowed
    );

  return ValidationResult.createValid();
}
