import CreditCard from "~/models/payment/credit_card";
import { ValidationCode, ValidationResult } from "..";

export default class CreditCardValidator {
  /**
   * カード番号をバリデーションする
   * @param {string} num - カード番号
   * @returns {ValidationResult}
   */
  static validateCardNumber(num) {
    /** 15桁以上であること */
    if (num.length < 15)
      return ValidationResult.createInvalid(
        "カード番号は15桁以上である必要があります",
        ValidationCode.LessThanMin
      );

    /** 19桁以下であること */
    if (num.length > 19)
      return ValidationResult.createInvalid(
        "カード番号は19桁以下である必要があります",
        ValidationCode.MoreThanMax
      );

    /** 半角数字のみであること */
    if (!/^[0-9]+$/.test(num))
      return ValidationResult.createInvalid(
        "カード番号に半角数字以外が含まれています",
        ValidationCode.IllegalChars
      );

    return ValidationResult.createValid();
  }

  /**
   * ハイフン付きカード番号をバリデーションする
   * @param {string} num - ハイフン付きカード番号
   * @returns {ValidationResult}
   */
  static validateHyphenatedCardNumber(num) {
    /** 18桁以上であること */
    if (num.length < 18)
      return ValidationResult.createInvalid(
        "ハイフン付きカード番号は18桁以上である必要があります",
        ValidationCode.LessThanMin
      );

    /** 23桁以下であること */
    if (num.length > 23)
      return ValidationResult.createInvalid(
        "ハイフン付きカード番号は23桁以下である必要があります",
        ValidationCode.MoreThanMax
      );

    /** 半角数字またはハイフンのみであること */
    if (!/^[0-9\-]+$/.test(num))
      return ValidationResult.createInvalid(
        "カード番号に半角数字またはハイフン以外が含まれています",
        ValidationCode.IllegalChars
      );

    /** ハイフンの位置が正しいこと */
    let correctlyHyphenated = true;
    for (let idx in num) {
      idx = Number(idx);

      if (idx === 0 && num.charAt(idx) === "-") {
        correctlyHyphenated = false;
        break;
      } else if (idx === num.length - 1 && num.charAt(idx) === "-") {
        correctlyHyphenated = false;
        break;
      } else {
        if (idx % (CreditCard.hyphenateAfter + 1) === 4) {
          if (num.charAt(idx) !== "-") {
            correctlyHyphenated = false;
            break;
          }
        } else {
          if (num.charAt(idx) === "-") {
            correctlyHyphenated = false;
            break;
          }
        }
      }
    }
    if (!correctlyHyphenated)
      return ValidationResult.createInvalid(
        "ハイフンの箇所が不正です",
        ValidationCode.IllegalFormat
      );

    return ValidationResult.createValid();
  }

  /**
   * 月をバリデーションする
   * @param {number} month
   * @returns {ValidationResult}
   */
  static validateExpiryMonth(month) {
    /** 1以上であること */
    if (month < 1)
      return ValidationResult.createInvalid(
        "有効期限月は1以上である必要があります",
        ValidationCode.LessThanMin
      );

    /** 12以下であること */
    if (month > 12)
      return ValidationResult.createInvalid(
        "有効期限月は12以下である必要があります",
        ValidationCode.MoreThanMax
      );

    return ValidationResult.createValid();
  }

  /**
   * ２桁強制の月をバリデーションする（"01"、"02"など）
   * @param {string} month
   * @returns {ValidationResult}
   */
  static validate2DigitExpiryMonth(month) {
    /** 空でないこと */
    if (!month)
      return ValidationResult.createInvalid(
        "有効期限月は空でない必要があります",
        ValidationCode.EmptyNotAllowed
      );

    /** 半角数字以外が含まれている場合、無効と判定 */
    if (/[^0-9]/.test(month))
      return ValidationResult.createInvalid(
        "有効期限月は半角数字のみである必要があります",
        ValidationCode.IllegalChars
      );

    /** 1より小さい場合、無効と判定 */
    if (Number(month) < 1)
      return ValidationResult.createInvalid(
        "有効期限月は1以上である必要があります",
        ValidationCode.LessThanMin
      );

    /** 12より大きい場合、無効と判定 */
    if (Number(month) > 12)
      return ValidationResult.createInvalid(
        "有効期限月は12以下である必要があります",
        ValidationCode.MoreThanMax
      );

    return ValidationResult.createValid();
  }

  /**
   * 年をバリデーションする
   * @param {string} year
   * @returns {ValidationResult}
   */
  static validateExpiryYear(year) {
    /** 空でないこと */
    if (!year)
      return ValidationResult.createInvalid(
        "有効期限年は空でない必要があります",
        ValidationCode.EmptyNotAllowed
      );

    /** 半角数字のみであること */
    if (/[^0-9]/.test(year))
      return ValidationResult.createInvalid(
        "有効期限年は半角数字のみである必要があります",
        ValidationCode.IllegalChars
      );

    /** 4桁であること */
    if (year.length !== 4)
      return ValidationResult.createInvalid(
        "有効期限年は4桁である必要があります",
        ValidationCode.IllegalLength
      );

    return ValidationResult.createValid();
  }

  /**
   * セキュリティコードをバリデーションする
   * @param {string} code - セキュリティコード
   * @returns {ValidationResult}
   */
  static validateSecurityCode(code) {
    /** 空でないこと */
    if (!code)
      return ValidationResult.createInvalid(
        "セキュリティコードは空でない必要があります",
        ValidationCode.EmptyNotAllowed
      );

    /** 半角数字のみであること */
    if (/[^0-9]/.test(code))
      return ValidationResult.createInvalid(
        "セキュリティコードは半角数字のみである必要があります",
        ValidationCode.IllegalChars
      );

    /** 3桁または4桁であること */
    if (![3, 4].includes(code.length))
      return ValidationResult.createInvalid(
        "セキュリティコードは3桁または4桁である必要があります",
        ValidationCode.IllegalLength
      );

    return ValidationResult.createValid();
  }

  /**
   * 名義人をバリデーションする
   * @param {string} name
   * @returns {ValidationResult}
   */
  static validateName(name) {
    /** 空でないこと */
    if (!name)
      return ValidationResult.createInvalid(
        "名義人は空でない必要があります",
        ValidationCode.EmptyNotAllowed
      );

    /** 半角英字または空白のみであること */
    if (/[^a-zA-Z ]/.test(name))
      return ValidationResult.createInvalid(
        "名義人名は半角英字または空白のみである必要があります",
        ValidationCode.IllegalChars
      );

    return ValidationResult.createValid();
  }

  /**
   * トークンをバリデーションする
   * @param {string} token
   * @param {boolean} isOptional
   * @returns {ValidationResult}
   */
  static validateToken(token, isOptional = false) {
    /** 空でないこと */
    if (!token)
      return isOptional
        ? ValidationResult.createValid()
        : ValidationResult.createInvalid(
            "トークンは必須です",
            ValidationCode.EmptyNotAllowed
          );

    return ValidationResult.createValid();
  }

  /**
   * トークンキーをバリデーションする
   * @param {string} tokenKey
   * @param {boolean} isOptional
   * @returns {ValidationResult}
   */
  static validateTokenKey(tokenKey, isOptional = false) {
    /** 空でないこと */
    if (!tokenKey)
      return isOptional
        ? ValidationResult.createValid()
        : ValidationResult.createInvalid(
            "トークンキーは必須です",
            ValidationCode.EmptyNotAllowed
          );

    return ValidationResult.createValid();
  }
}
